<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Blood Request Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
					<div class="card-body p-9">
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Blood Type</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.blood_type}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Phone</label>
							<div class="col-lg-8 fv-row">
								<span class="fw-semibold text-gray-800 fs-6">{{userById.phone_number}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Patient Name</label>
							<div class="col-lg-8">
								<a href="javascript:;" class="fw-semibold fs-6 text-gray-800 text-hover-primary">{{userById.patient_name}}</a>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Blood Group</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.blood_group}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Required Date</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.required_date}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Units</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.units}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Location</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.location}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Critical Level</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.is_critical}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Notes</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.notes}}</span>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="blood_request_list" class="btn btn-primary">Back</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>