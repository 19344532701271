import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Badge } from "../models/badge";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class BadgeService {
	
	private getBadgeUrl = environment.baseURL + "badge/getBadge";

	private getBadgeByIdUrl = environment.baseURL + "badge/getBadgeById";

	private addBadgeUrl = environment.baseURL + "badge/createBadge";

	private updateBadgeUrl = environment.baseURL + "badge/updateBadge";

	private deleteBadgeUrl = environment.baseURL + "badge/deleteBadge";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getBadge(params: any): Observable<Badge> {
		return this.http
		.post<Badge>(this.getBadgeUrl, params, this.httpOptions)
		.pipe(
			map((BadgeList) => {
			return BadgeList;
			})
		);
	}
	
	getBadgeById(badgeId: any): Observable<Badge> {
		let badgeIds = { badgeId: badgeId };
		return this.http
		.post<Badge>(this.getBadgeByIdUrl, badgeIds, this.httpOptions)
		.pipe(
			map((BadgeList) => {
			return BadgeList;
			})
		);
	}

	addBadge(badge: Badge): Observable<Badge> {
		return this.http
		.post<Badge>(this.addBadgeUrl, badge, this.httpOptions)
		.pipe(
			map((badge) => {
			return badge;
			})
		);
	}

	updateBadge(badge: Badge): Observable<Badge> {
		return this.http
		.post<Badge>(this.updateBadgeUrl, badge, this.httpOptions)
		.pipe(
			map((badge) => {
			return badge;
			})
		);
	}

	deleteBadge(badge: Badge): Observable<Badge> {
		return this.http
		.post<Badge>(this.deleteBadgeUrl, badge, this.httpOptions)
		.pipe(
			map((badge) => {
			return badge;
			})
		);
	}
}
