import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonService } from '../services/common.service';
import { User } from '../models/user';

@Component({
	selector: 'app-update-profile',
	templateUrl: './update-profile.component.html',
	styleUrls: [
		
	]
})
export class UpdateProfileComponent implements OnInit {

	updateProfileForm = new FormGroup({
		universal_name: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
		name_on_card: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
		email: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
		userId: new FormControl("", [Validators.required])
  	});

	get f() {
		return this.updateProfileForm.controls;
	}

	userList: any;
	userById: any;
	btnclose: boolean = false;
	smallSidebar: boolean = false;
	profileDIv: boolean = false;
	currentUserList: any;
	currentUserData: any;

	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		universal_nameError: "",
		name_on_cardError: "",
		emailError: "",
		phoneError: ""
	};
	constructor(
		private commonService: CommonService,
		private router: Router,
	) { }

	ngOnInit(): void {
		this.profile();
		this.currentUserData = localStorage.getItem('currentUser');
		this.currentUserList = JSON.parse(this.currentUserData);
	}
	
	customJS(): void{
		if(this.btnclose){
			this.btnclose = !this.btnclose;
			this.smallSidebar = !this.smallSidebar;
		} else{
			this.btnclose = true;
			this.smallSidebar = true;
		}
	}

	openProfileModel(): void{
		this.profileDIv = !this.profileDIv;
	}

	profile(): void {
		this.commonService.getProfile().subscribe(
		(data) => {
			if (data.status == 200) {
			this.userList = data.data;
			this.updateProfileForm.controls["universal_name"].setValue(data.data.universal_name);
			this.updateProfileForm.controls["name_on_card"].setValue(data.data.name_on_card);
			this.updateProfileForm.controls["phone"].setValue(data.data.phone);
			this.updateProfileForm.controls["email"].setValue(data.data.email);
			this.updateProfileForm.controls["userId"].setValue(data.data.id);
			} else {
			this.userList = [];
			}
		},
		(error) => {
		}
		);
	}

	updateProfile($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		const phone_no_pattern = /^((\\+91-?)|0)?[0-9]{10}$/;
		const name_pattern = /^[a-zA-Z ]*$/;

		let universal_name = this.updateProfileForm.value.universal_name.trim();
		let name_on_card = this.updateProfileForm.value.name_on_card.trim();
		let phone = this.updateProfileForm.value.phone.trim();
		let userId = this.updateProfileForm.value.userId;
		
		var phone_err = '';
		if (!phone_no_pattern.test(phone)) {
			phone_err = '';
			this.formErrorMessages.phoneError = "Phone Number field is required.";
		} else{
			phone_err = '1';
			this.formErrorMessages.phoneError = "";
		}
		
		var universal_name_err = '';
		if (!name_pattern.test(universal_name)) {
			universal_name_err = '';
			this.formErrorMessages.universal_nameError = "Phone Number field is required.";
		} else{
			universal_name_err = '1';
			this.formErrorMessages.universal_nameError = "";
		}
		
		var name_on_card_err = '';
		if (!name_pattern.test(name_on_card)) {
			name_on_card_err = '';
			this.formErrorMessages.name_on_cardError = "Phone Number field is required.";
		} else{
			name_on_card_err = '1';
			this.formErrorMessages.name_on_cardError = "";
		}

		if (
		!universal_name ||
		!name_on_card ||
		!phone ||
		!phone_err ||
		!universal_name_err ||
		!name_on_card_err ||
		!userId
		) {
		if (!universal_name) {
			this.formErrorMessages.universal_nameError = "First Name field is required.";
		} else {
			if (!name_pattern.test(universal_name)) {
				this.formErrorMessages.universal_nameError = "Phone Number field is required.";
			} else{
				this.formErrorMessages.universal_nameError = "";
			}
		}
		if (!name_on_card) {
			this.formErrorMessages.name_on_cardError = "Last Name field is required.";
		} else {
			if (!name_pattern.test(name_on_card)) {
				this.formErrorMessages.name_on_cardError = "Phone Number field is required.";
			} else{
				this.formErrorMessages.name_on_cardError = "";
			}
		}
		if (!phone) {
			this.formErrorMessages.phoneError = "Phone Number field is required.";
		} else {
			if (!phone_no_pattern.test(phone)) {
				this.formErrorMessages.phoneError = "Phone Number field is required.";
			} else{
				this.formErrorMessages.phoneError = "";
			}
		}
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		} else{
			($event.submitter as HTMLButtonElement).disabled = true;
			this.commonService
			.updateProfile({
				universal_name,
				name_on_card,
				phone,
				userId
			} as User)
			.subscribe(
				(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						this.router.navigate(['profile']);
					}, 2000);
				} else {
					this.errorMessage = data.message;
					($event.submitter as HTMLButtonElement).disabled = false;
	
				}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
		}
	}
}