import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/user";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class DashboardService {
	
	private getUserCounterDashboardUrl = environment.baseURL + "dashboard/getUserCounterDashboard";
	private getBloodCounterDashboardUrl = environment.baseURL + "dashboard/getBloodCounterDashboard";
	private getAppointmentCounterDashboardUrl = environment.baseURL + "dashboard/getAppointmentCounterDashboard";
	
	private getLatestBloodListDashboardUrl = environment.baseURL + "dashboard/getLatestBloodListDashboard";
	private getLatestUserListDashboardUrl = environment.baseURL + "dashboard/getLatestUserListDashboard";
	
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}

	getUserCounterDashboard(): Observable<User> {
		return this.http
		.get<User>(this.getUserCounterDashboardUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getBloodCounterDashboard(): Observable<User> {
		return this.http
		.get<User>(this.getBloodCounterDashboardUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getAppointmentCounterDashboard(): Observable<User> {
		return this.http
		.get<User>(this.getAppointmentCounterDashboardUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getLatestBloodListDashboard(): Observable<User> {
		return this.http
		.get<User>(this.getLatestBloodListDashboardUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getLatestUserListDashboard(): Observable<User> {
		return this.http
		.get<User>(this.getLatestUserListDashboardUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

}
