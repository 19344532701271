import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { PProfileRoutingModule } from './p-profile-routing.module';
import { PProfileComponent } from './p-profile.component';

@NgModule({
  imports: [
    CommonModule,
    PProfileRoutingModule
  ],
  declarations: [PProfileComponent],
  providers: [AuthGuard]
})
export class PProfileModule { }
