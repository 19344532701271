<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="w-lg-500px p-10">
                        <form class="form w-100" [formGroup]="loginForm" (ngSubmit)="login()" id="kt_sign_in_form">
                            <div class="text-center mb-11">
                                <a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 50%;margin-bottom: 30px;" /> </a>
                                <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
                            </div>
                            <div class="fv-row mb-8">
                                <input type="text" placeholder="Email" formControlName="email" autocomplete="off" class="form-control bg-transparent" [ngClass]="{'inputError': f.email.touched && f.email.invalid && f.email.errors && f.email.errors.required }" [ngClass]="{'inputError': formErrorMessages.emailError }" />
                            </div>
                            <div class="fv-row mb-3">
                                <input type="password" placeholder="Password" formControlName="password" autocomplete="off" class="form-control bg-transparent" [ngClass]="{'inputError': f.password.touched && f.password.invalid && f.password.errors && f.password.errors.required }" [ngClass]="{'inputError': formErrorMessages.passwordError }" />
                            </div>
                            <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div></div>
                                <a href="javascript:;" class="link-primary">Forgot Password ?</a>
                            </div>
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                            <div class="d-grid mb-10">
                                <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
                                    <span class="indicator-label">Sign In</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>    