import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { HDashboardRoutingModule } from './h-dashboard-routing.module';
import { HDashboardComponent } from './h-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    HDashboardRoutingModule
  ],
  declarations: [HDashboardComponent],
  providers: [AuthGuard]
})
export class HDashboardModule { }
