<div class="d-flex flex-column flex-root">
	<div class="page d-flex flex-row flex-column-fluid">
		<div id="kt_aside" class="aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
			<div class="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
				<div class="aside-user d-flex align-items-sm-center justify-content-center py-5">
					<div class="symbol symbol-50px">
						<img src="assets/media/avatars/blank.png" alt="" />
					</div>
					<div class="aside-user-info flex-row-fluid flex-wrap ms-5">
						<div class="d-flex">
							<div class="flex-grow-1 me-2">
								<a href="javascript:;" class="text-white text-hover-primary fs-6 fw-bold">{{name}}</a>
								<div class="d-flex align-items-center text-success fs-9">
								<span class="bullet bullet-dot bg-success me-1"></span>online</div>
							</div>
							<div class="me-n2">
								<a href="javascript:;" class="btn btn-icon btn-sm btn-active-color-primary mt-n2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-overflow="true">
									<i class="ki-duotone ki-setting-2 text-muted fs-1">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</a>
								<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
									<div class="menu-item px-3">
										<div class="menu-content d-flex align-items-center px-3">
											<div class="symbol symbol-50px me-5">
												<img alt="Logo" src="assets/media/avatars/blank.png" />
											</div>
											<div class="d-flex flex-column">
												<div class="fw-bold d-flex align-items-center fs-5">{{name}}
													<span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2"  *ngIf="(role_type == '1')" >Admin</span>
													<span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2"  *ngIf="(role_type == '3')" >Blood Bank</span>
												</div>
												<a href="javascript:;" class="fw-semibold text-muted text-hover-primary fs-7">{{email}}</a>
											</div>
										</div>
									</div>
									<div class="separator my-2"></div>
									<div class="menu-item px-5">
										<a href="profile" *ngIf="(role_type == '1')" class="menu-link px-5">My Profile</a>
										<a href="b_profile" *ngIf="(role_type == '3')" class="menu-link px-5">My Profile</a>
										<a href="h_profile" *ngIf="(role_type == '4')" class="menu-link px-5">My Profile</a>
										<a href="p_profile" *ngIf="(role_type == '5')" class="menu-link px-5">My Profile</a>
									</div>
									<div class="menu-item px-5">
										<a href="logout" class="menu-link px-5">Sign Out</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="aside-menu flex-column-fluid">
				<div class="hover-scroll-overlay-y px-2 my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="5px">
					<div class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/dashboard' ? 'active' : ''" href="dashboard">
								<span class="menu-icon">
									<i class="ki-duotone ki-rocket fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Dashboard</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '3')" [ngClass]="currentRoute == '/b_dashboard' ? 'active' : ''" href="b_dashboard">
								<span class="menu-icon">
									<i class="ki-duotone ki-rocket fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Dashboard</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/role_list' ? 'active' : ''" href="role_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-13 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Role Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/user_list' ? 'active' : ''" href="user_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">User Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/blood_bank_list' ? 'active' : ''" href="blood_bank_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Blood Bank Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/hospital_list' ? 'active' : ''" href="hospital_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Hospital Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/path_lab_list' ? 'active' : ''" href="path_lab_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Path Lab Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/badge_list' ? 'active' : ''" href="badge_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Badge Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/version_control_list' ? 'active' : ''" href="version_control_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Version Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/banner_list' ? 'active' : ''" href="banner_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Banner Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/blood_request_list' ? 'active' : ''" href="blood_request_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Blood Request Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/blood_type_list' ? 'active' : ''" href="blood_type_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Blood Type Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/disease_list' ? 'active' : ''" href="disease_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Disease Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/appointment_list' ? 'active' : ''" href="appointment_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Appointment Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')" [ngClass]="currentRoute == '/community_list' ? 'active' : ''" href="community_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Community Manage</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>