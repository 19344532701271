import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-p-dashboard',
	templateUrl: './p-dashboard.component.html',
	styleUrls: [
		'./p-dashboard.component.css'
	]
})
export class PDashboardComponent implements OnInit {
	
	userCounter: any;
	bloodCounter: any;
	appointmentCounter: any;

	userList: any;
	bloodList: any;

	constructor(
		private dashboardService: DashboardService,
	) {}

	ngOnInit() {
	}
}