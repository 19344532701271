import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	private getProfileUrl = environment.baseURL + 'common/profile';
	private updateProfileUrl = environment.baseURL + "common/updateProfile";
	private changePasswordUrl = environment.baseURL + "common/changePassword";
	
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(private http: HttpClient) { }

	getProfile(): Observable<User> {
		let userData: any;
		userData = localStorage.getItem('currentUser');
		let userArray = JSON.parse(userData);
		let userId = userArray.data.id;
		let userIds = { userId: userId };
		return this.http.post<User>(this.getProfileUrl, userIds, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	updateProfile(user: User): Observable<User> {
		return this.http
		.post<User>(this.updateProfileUrl, user, this.httpOptions)
		.pipe(
			map((user) => {
			return user;
			})
		);
	}

	changePassword(user: User): Observable<User> {
		return this.http
		.post<User>(this.changePasswordUrl, user, this.httpOptions)
		.pipe(
			map((user) => {
			return user;
			})
		);
	}
}
