import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-b-dashboard',
	templateUrl: './b-dashboard.component.html',
	styleUrls: [
		'./b-dashboard.component.css'
	]
})
export class BDashboardComponent implements OnInit {
	
	userCounter: any;
	bloodCounter: any;
	appointmentCounter: any;

	userList: any;
	bloodList: any;

	constructor(
		private dashboardService: DashboardService,
	) {}

	ngOnInit() {
	}
}