import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { PathLabService } from "../services/pathLab.service";
import { PathLab } from "../models/pathLab";

@Component({
	selector: "app-path-lab-list",
	templateUrl: "./path-lab-list.component.html",
	styleUrls: ["./path-lab-list.component.css"],
})
export class PathLabListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addPathLabForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.required]),
		c_name: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
		city: new FormControl("", [Validators.required]),
		state: new FormControl("", [Validators.required]),
		country: new FormControl("", [Validators.required]),
		latitude: new FormControl("", [Validators.required]),
		longitude: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
  	});

  	updatePathLabForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.required]),
		c_name: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
		city: new FormControl("", [Validators.required]),
		state: new FormControl("", [Validators.required]),
		country: new FormControl("", [Validators.required]),
		latitude: new FormControl("", [Validators.required]),
		longitude: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		pathLabId: new FormControl("", [Validators.required]),
  	});

	deletePathLabForm = new FormGroup({
		pathLabId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addPathLabForm.controls;
	}

	pathLabList: any;
	pathLabById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		nameError : "",
		phoneError : "",
		c_nameError : "",
		c_phoneError : "",
		c_emailError : "",
		e_phoneError : "",
		addressError : "",
		cityError : "",
		stateError : "",
		countryError : "",
		latitudeError : "",
		longitudeError : "",
		statusError : "",
	};

	constructor(
		private pathLabService: PathLabService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getPathLab();
	}

	getPathLab(): void {
		this.pathLabList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.pathLabService.getPathLab(dataTablesParameters).subscribe(
				(data) => {
					that.pathLabList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openPathLabAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openPathLabEdit(content: any, pathLabId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.pathLabService.getPathLabById(pathLabId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.pathLabById = data.data;
			this.updatePathLabForm.controls["name"].setValue(data.data.name);
			this.updatePathLabForm.controls["phone"].setValue(data.data.phone);
			this.updatePathLabForm.controls["c_name"].setValue(data.data.c_name);
			this.updatePathLabForm.controls["c_phone"].setValue(data.data.c_phone);
			this.updatePathLabForm.controls["c_email"].setValue(data.data.c_email);
			this.updatePathLabForm.controls["e_phone"].setValue(data.data.e_phone);
			this.updatePathLabForm.controls["address"].setValue(data.data.address);
			this.updatePathLabForm.controls["city"].setValue(data.data.city);
			this.updatePathLabForm.controls["state"].setValue(data.data.state);
			this.updatePathLabForm.controls["country"].setValue(data.data.country);
			this.updatePathLabForm.controls["latitude"].setValue(data.data.latitude);
			this.updatePathLabForm.controls["longitude"].setValue(data.data.longitude);
			this.updatePathLabForm.controls["status"].setValue(data.data.status);
			this.updatePathLabForm.controls["pathLabId"].setValue(data.data.id);
			} else {
			this.pathLabById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openPathLabView(content: any, pathLabId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.pathLabService.getPathLabById(pathLabId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.pathLabById = data.data;
			} else {
			this.pathLabById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openPathLabDelete(content: any, pathLabId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deletePathLabForm.controls["pathLabId"].setValue(pathLabId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addPathLab($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.addPathLabForm.value.name.trim();
		let phone = this.addPathLabForm.value.phone.trim();
		let c_name = this.addPathLabForm.value.c_name.trim();
		let c_phone = this.addPathLabForm.value.c_phone.trim();
		let c_email = this.addPathLabForm.value.c_email.trim();
		let e_phone = this.addPathLabForm.value.e_phone.trim();
		let address = this.addPathLabForm.value.address.trim();
		let city = this.addPathLabForm.value.city.trim();
		let state = this.addPathLabForm.value.state.trim();
		let country = this.addPathLabForm.value.country.trim();
		let latitude = this.addPathLabForm.value.latitude.trim();
		let longitude = this.addPathLabForm.value.longitude.trim();
		let status = this.addPathLabForm.value.status.trim();
		if (
		!name ||
		!phone ||
		!c_name ||
		!c_phone ||
		!c_email ||
		!e_phone ||
		!address ||
		!city ||
		!state ||
		!country ||
		!latitude ||
		!longitude ||
		!status
		) {
		if (!name) {
			this.formErrorMessages.nameError = "Blood Bank name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!phone) {
			this.formErrorMessages.phoneError = "Blood Bank phone field is required.";
		} else {
			this.formErrorMessages.phoneError = "";
		}
		if (!c_name) {
			this.formErrorMessages.c_nameError = "Blood Bank c_name field is required.";
		} else {
			this.formErrorMessages.c_nameError = "";
		}
		if (!c_phone) {
			this.formErrorMessages.c_phoneError = "Blood Bank c_phone field is required.";
		} else {
			this.formErrorMessages.c_phoneError = "";
		}
		if (!c_email) {
			this.formErrorMessages.c_emailError = "Blood Bank c_email field is required.";
		} else {
			this.formErrorMessages.c_emailError = "";
		}
		if (!e_phone) {
			this.formErrorMessages.e_phoneError = "Blood Bank e_phone field is required.";
		} else {
			this.formErrorMessages.e_phoneError = "";
		}
		if (!address) {
			this.formErrorMessages.addressError = "Blood Bank address field is required.";
		} else {
			this.formErrorMessages.addressError = "";
		}
		if (!city) {
			this.formErrorMessages.cityError = "Blood Bank city field is required.";
		} else {
			this.formErrorMessages.cityError = "";
		}
		if (!state) {
			this.formErrorMessages.stateError = "Blood Bank state field is required.";
		} else {
			this.formErrorMessages.stateError = "";
		}
		if (!country) {
			this.formErrorMessages.countryError = "Blood Bank country field is required.";
		} else {
			this.formErrorMessages.countryError = "";
		}
		if (!latitude) {
			this.formErrorMessages.latitudeError = "Blood Bank latitude field is required.";
		} else {
			this.formErrorMessages.latitudeError = "";
		}
		if (!longitude) {
			this.formErrorMessages.longitudeError = "Blood Bank longitude field is required.";
		} else {
			this.formErrorMessages.longitudeError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.pathLabService
		.addPathLab({
			name,
			phone,
			c_name,
			c_phone,
			c_email,
			e_phone,
			address,
			city,
			state,
			country,
			latitude,
			longitude,
			status
		} as PathLab)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addPathLabModalClose")?.click();
				this.addPathLabForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updatePathLab($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.updatePathLabForm.value.name.trim();
		let phone = this.updatePathLabForm.value.phone.trim();
		let c_name = this.updatePathLabForm.value.c_name.trim();
		let c_phone = this.updatePathLabForm.value.c_phone.trim();
		let c_email = this.updatePathLabForm.value.c_email.trim();
		let e_phone = this.updatePathLabForm.value.e_phone.trim();
		let address = this.updatePathLabForm.value.address.trim();
		let city = this.updatePathLabForm.value.city.trim();
		let state = this.updatePathLabForm.value.state.trim();
		let country = this.updatePathLabForm.value.country.trim();
		let latitude = this.updatePathLabForm.value.latitude.trim();
		let longitude = this.updatePathLabForm.value.longitude.trim();
		let status = this.updatePathLabForm.value.status.trim();
		let pathLabId = this.updatePathLabForm.value.pathLabId;
		
		if (
		!name ||
		!phone ||
		!c_name ||
		!c_phone ||
		!c_email ||
		!e_phone ||
		!address ||
		!city ||
		!state ||
		!country ||
		!latitude ||
		!longitude ||
		!status ||
		!pathLabId
		) {
		if (!name) {
			this.formErrorMessages.nameError = "PathLab name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!phone) {
			this.formErrorMessages.phoneError = "Blood Bank phone field is required.";
		} else {
			this.formErrorMessages.phoneError = "";
		}
		if (!c_name) {
			this.formErrorMessages.c_nameError = "Blood Bank c_name field is required.";
		} else {
			this.formErrorMessages.c_nameError = "";
		}
		if (!c_phone) {
			this.formErrorMessages.c_phoneError = "Blood Bank c_phone field is required.";
		} else {
			this.formErrorMessages.c_phoneError = "";
		}
		if (!c_email) {
			this.formErrorMessages.c_emailError = "Blood Bank c_email field is required.";
		} else {
			this.formErrorMessages.c_emailError = "";
		}
		if (!e_phone) {
			this.formErrorMessages.e_phoneError = "Blood Bank e_phone field is required.";
		} else {
			this.formErrorMessages.e_phoneError = "";
		}
		if (!address) {
			this.formErrorMessages.addressError = "Blood Bank address field is required.";
		} else {
			this.formErrorMessages.addressError = "";
		}
		if (!city) {
			this.formErrorMessages.cityError = "Blood Bank city field is required.";
		} else {
			this.formErrorMessages.cityError = "";
		}
		if (!state) {
			this.formErrorMessages.stateError = "Blood Bank state field is required.";
		} else {
			this.formErrorMessages.stateError = "";
		}
		if (!country) {
			this.formErrorMessages.countryError = "Blood Bank country field is required.";
		} else {
			this.formErrorMessages.countryError = "";
		}
		if (!latitude) {
			this.formErrorMessages.latitudeError = "Blood Bank latitude field is required.";
		} else {
			this.formErrorMessages.latitudeError = "";
		}
		if (!longitude) {
			this.formErrorMessages.longitudeError = "Blood Bank longitude field is required.";
		} else {
			this.formErrorMessages.longitudeError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.pathLabService
		.updatePathLab({
			name,
			phone,
			c_name,
			c_phone,
			c_email,
			e_phone,
			address,
			city,
			state,
			country,
			latitude,
			longitude,
			status,
			pathLabId
		} as PathLab)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updatePathLabModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deletePathLab(): void {

		let pathLabId = this.deletePathLabForm.value.pathLabId;
		if (!pathLabId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.pathLabService.deletePathLab({ pathLabId } as PathLab).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deletePathLabModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}