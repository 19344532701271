<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Blood Bank Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card">
					<div class="card-header border-0 pt-6">
						<div class="card-title"></div>
						<div class="card-toolbar">
							<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
								<button type="button" class="btn btn-primary" (click)="openBloodBankAdd(addBloodBankContent)">
									<i class="ki-duotone ki-plus fs-2"></i>        Add Blood Bank
								</button>
							</div>
							<ng-template #addBloodBankContent let-modal>
								<div class="modal-header" id="modal-basic-title">
									<h2 class="fw-bold">Add Blood Bank</h2>
									<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
										<i class="ki-duotone ki-cross fs-1">
											<span class="path1"></span>
											<span class="path2"></span>
										</i>
									</div>
								</div>
								<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
									<form [formGroup]="addBloodBankForm" (ngSubmit)="addBloodBank($event)" class="form">
										<div class="d-flex flex-column scroll-y me-n7 pe-7">
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Name</label>
													<input type="text" class="form-control form-control-solid" formControlName="name"  placeholder="Name" [ngClass]="{'inputError': f.name.touched && f.name.invalid && f.name.errors && f.name.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.nameError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Phone</label>
													<input type="text" class="form-control form-control-solid" formControlName="phone"  placeholder="Phone" [ngClass]="{'inputError': f.phone.touched && f.phone.invalid && f.phone.errors && f.phone.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.phoneError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Contact Person Name</label>
													<input type="text" class="form-control form-control-solid" formControlName="c_name"  placeholder="Contact Person Name" [ngClass]="{'inputError': f.c_name.touched && f.c_name.invalid && f.c_name.errors && f.c_name.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.c_nameError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Contact Person Phone</label>
													<input type="text" class="form-control form-control-solid" formControlName="c_phone"  placeholder="Contact Person Phone" [ngClass]="{'inputError': f.c_phone.touched && f.c_phone.invalid && f.c_phone.errors && f.c_phone.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.c_phoneError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Contact Person Email</label>
													<input type="text" class="form-control form-control-solid" formControlName="c_email"  placeholder="Contact Person Email" [ngClass]="{'inputError': f.c_email.touched && f.c_email.invalid && f.c_email.errors && f.c_email.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.c_emailError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Emergency Phone</label>
													<input type="text" class="form-control form-control-solid" formControlName="e_phone"  placeholder="Emergency Phone" [ngClass]="{'inputError': f.e_phone.touched && f.e_phone.invalid && f.e_phone.errors && f.e_phone.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.e_phoneError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Address</label>
													<input type="text" class="form-control form-control-solid" formControlName="address"  placeholder="Address" [ngClass]="{'inputError': f.address.touched && f.address.invalid && f.address.errors && f.address.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.addressError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">City</label>
													<input type="text" class="form-control form-control-solid" formControlName="city"  placeholder="City" [ngClass]="{'inputError': f.city.touched && f.city.invalid && f.city.errors && f.city.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.cityError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">State</label>
													<input type="text" class="form-control form-control-solid" formControlName="state"  placeholder="State" [ngClass]="{'inputError': f.state.touched && f.state.invalid && f.state.errors && f.state.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.stateError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Country</label>
													<input type="text" class="form-control form-control-solid" formControlName="country"  placeholder="Country" [ngClass]="{'inputError': f.country.touched && f.country.invalid && f.country.errors && f.country.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.countryError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Latitude</label>
													<input type="text" class="form-control form-control-solid" formControlName="latitude"  placeholder="Latitude" [ngClass]="{'inputError': f.latitude.touched && f.latitude.invalid && f.latitude.errors && f.latitude.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.latitudeError }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Longitude</label>
													<input type="text" class="form-control form-control-solid" formControlName="longitude"  placeholder="Longitude" [ngClass]="{'inputError': f.longitude.touched && f.longitude.invalid && f.longitude.errors && f.longitude.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.longitudeError }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Status</label>
													<input type="text" class="form-control form-control-solid" formControlName="status"  placeholder="Status" [ngClass]="{'inputError': f.status.touched && f.status.invalid && f.status.errors && f.status.errors.required }"
													[ngClass]="{'inputError': formErrorMessages.statusError }" />
												</div>
											</div>
											<div *ngIf="errorMessage">
												<div class="alert alert-danger" role="alert">
													{{errorMessage}}
												</div>
											</div>
											<div *ngIf="successMessage">
												<div class="alert alert-success" role="alert">
													{{successMessage}}
												</div>
											</div>
										</div>
										<div class="text-center pt-15">
											<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
											<button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
												<span class="indicator-label">Submit</span>
												<span class="indicator-progress">Please wait...
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
									</form>
								</div>
							</ng-template>
						</div>
					</div>
					<div class="card-body py-4">
						<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions">
							<thead>
								<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
									<th>SR. No.</th>
									<th>Name</th>
									<th>Phone</th>
									<th>Address</th>
									<th>Status</th>
									<th>Created</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody class="text-gray-600 fw-semibold" *ngIf="bloodBankList?.length != 0">
								<tr *ngFor="let bloodBank of bloodBankList;let i = index">
									<td>{{i+1}}</td>
									<td>{{bloodBank.name}}</td>
									<td>{{bloodBank.phone}}</td>
									<td>{{bloodBank.address}}</td>
									<td>{{bloodBank.status}}</td>
									<td>{{bloodBank.created | date :'medium'}}</td>
									<td>
										<a href="javascript:;" (click)="openBloodBankView(viewBloodBankContent, bloodBank.id)" class="btn btn-sm btn-icon btn-active-color-primary">
											<i class="ki-outline ki-eye fs-2 text-primary">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a>
										<a href="javascript:;" (click)="openBloodBankEdit(updateBloodBankContent, bloodBank.id)" class="btn btn-sm btn-icon btn-active-color-info">
											<i class="ki-outline ki-notepad-edit fs-2 text-info">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a>
										<!-- <a href="javascript:;" class="btn btn-sm btn-icon btn-active-color-danger deleteBtn">
											<i class="ki-outline ki-trash fs-2 text-danger">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a> -->
									</td>
								</tr>
							</tbody>
							<tbody class="text-gray-600 fw-semibold" *ngIf="bloodBankList?.length == 0">
								<tr>
									<td>No Record Found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #updateBloodBankContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">Edit Blood Bank</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
		<form [formGroup]="updateBloodBankForm" (ngSubmit)="updateBloodBank($event)" class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7">
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Name</label>
						<input type="text" class="form-control form-control-solid" formControlName="name"  placeholder="Name" [ngClass]="{'inputError': f.name.touched && f.name.invalid && f.name.errors && f.name.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.nameError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Phone</label>
						<input type="text" class="form-control form-control-solid" formControlName="phone"  placeholder="Phone" [ngClass]="{'inputError': f.phone.touched && f.phone.invalid && f.phone.errors && f.phone.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.phoneError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Contact Person Name</label>
						<input type="text" class="form-control form-control-solid" formControlName="c_name"  placeholder="Contact Person Name" [ngClass]="{'inputError': f.c_name.touched && f.c_name.invalid && f.c_name.errors && f.c_name.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.c_nameError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Contact Person Phone</label>
						<input type="text" class="form-control form-control-solid" formControlName="c_phone"  placeholder="Contact Person Phone" [ngClass]="{'inputError': f.c_phone.touched && f.c_phone.invalid && f.c_phone.errors && f.c_phone.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.c_phoneError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Contact Person Email</label>
						<input type="text" class="form-control form-control-solid" formControlName="c_email"  placeholder="Contact Person Email" [ngClass]="{'inputError': f.c_email.touched && f.c_email.invalid && f.c_email.errors && f.c_email.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.c_emailError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Emergency Phone</label>
						<input type="text" class="form-control form-control-solid" formControlName="e_phone"  placeholder="Emergency Phone" [ngClass]="{'inputError': f.e_phone.touched && f.e_phone.invalid && f.e_phone.errors && f.e_phone.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.e_phoneError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Address</label>
						<input type="text" class="form-control form-control-solid" formControlName="address"  placeholder="Address" [ngClass]="{'inputError': f.address.touched && f.address.invalid && f.address.errors && f.address.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.addressError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">City</label>
						<input type="text" class="form-control form-control-solid" formControlName="city"  placeholder="City" [ngClass]="{'inputError': f.city.touched && f.city.invalid && f.city.errors && f.city.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.cityError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">State</label>
						<input type="text" class="form-control form-control-solid" formControlName="state"  placeholder="State" [ngClass]="{'inputError': f.state.touched && f.state.invalid && f.state.errors && f.state.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.stateError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Country</label>
						<input type="text" class="form-control form-control-solid" formControlName="country"  placeholder="Country" [ngClass]="{'inputError': f.country.touched && f.country.invalid && f.country.errors && f.country.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.countryError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Latitude</label>
						<input type="text" class="form-control form-control-solid" formControlName="latitude"  placeholder="Latitude" [ngClass]="{'inputError': f.latitude.touched && f.latitude.invalid && f.latitude.errors && f.latitude.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.latitudeError }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Longitude</label>
						<input type="text" class="form-control form-control-solid" formControlName="longitude"  placeholder="Longitude" [ngClass]="{'inputError': f.longitude.touched && f.longitude.invalid && f.longitude.errors && f.longitude.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.longitudeError }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Status</label>
						<input type="text" class="form-control form-control-solid" formControlName="status"  placeholder="Status" [ngClass]="{'inputError': f.status.touched && f.status.invalid && f.status.errors && f.status.errors.required }"
						[ngClass]="{'inputError': formErrorMessages.statusError }" />
					</div>
				</div>
				<div *ngIf="errorMessage">
					<div class="alert alert-danger" role="alert">
						{{errorMessage}}
					</div>
				</div>
				<div *ngIf="successMessage">
					<div class="alert alert-success" role="alert">
						{{successMessage}}
					</div>
				</div>
			</div>
			<div class="text-center pt-15">
				<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
				<button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
					<span class="indicator-label">Submit</span>
					<span class="indicator-progress">Please wait...
					<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewBloodBankContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">View Blood Bank</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y">
		<form class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				<div class="table-responsive">
					<table class="table table-striped" style="line-height: 50px;;">
						<tr>
							<th class="text-right">Name</th>
							<td>{{bloodBankById.name}}</td>
						</tr>
						<tr>
							<th class="text-right">Phone</th>
							<td>{{bloodBankById.phone}}</td>
						</tr>
						<tr>
							<th class="text-right">Contact Person Name</th>
							<td>{{bloodBankById.c_name}}</td>
						</tr>
						<tr>
							<th class="text-right">Contact Person Phone</th>
							<td>{{bloodBankById.c_phone}}</td>
						</tr>
						<tr>
							<th class="text-right">Contact Person Email</th>
							<td>{{bloodBankById.c_email}}</td>
						</tr>
						<tr>
							<th class="text-right">Emergency Phone</th>
							<td>{{bloodBankById.e_phone}}</td>
						</tr>
						<tr>
							<th class="text-right">Address</th>
							<td>{{bloodBankById.address}}</td>
						</tr>
						<tr>
							<th class="text-right">City</th>
							<td>{{bloodBankById.city}}</td>
						</tr>
						<tr>
							<th class="text-right">State</th>
							<td>{{bloodBankById.state}}</td>
						</tr>
						<tr>
							<th class="text-right">Country</th>
							<td>{{bloodBankById.country}}</td>
						</tr>
						<tr>
							<th class="text-right">Latitude</th>
							<td>{{bloodBankById.latitude}}</td>
						</tr>
						<tr>
							<th class="text-right">Longitude</th>
							<td>{{bloodBankById.longitude}}</td>
						</tr>
						<tr>
							<th class="text-right">Status</th>
							<td>{{bloodBankById.status}}</td>
						</tr>
						<tr>
							<th class="text-right">Created</th>
							<td>{{bloodBankById.created}}</td>
						</tr>
					</table>
					<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>