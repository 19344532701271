import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { UserBloodRequestRoutingModule } from "./user-blood-request-routing.module";
import { UserBloodRequestComponent } from "./user-blood-request.component";

@NgModule({
  imports: [
    CommonModule,
    UserBloodRequestRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [UserBloodRequestComponent],
  providers: [AuthGuard],
})
export class UserBloodRequestModule {}
