import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { VersionControlService } from "../services/versionControl.service";
import { VersionControl } from "../models/versionControl";

@Component({
	selector: "app-version-control-list",
	templateUrl: "./version-control-list.component.html",
	styleUrls: ["./version-control-list.component.css"],
})
export class VersionControlListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addVersionControlForm = new FormGroup({
		version_name: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		version_code: new FormControl("", [Validators.required]),
		force_update: new FormControl("", [Validators.required]),
		recommended_update: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
  	});

  	updateVersionControlForm = new FormGroup({
		version_name: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		version_code: new FormControl("", [Validators.required]),
		force_update: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		recommended_update: new FormControl("", [Validators.required]),
		versionControlId: new FormControl("", [Validators.required]),
  	});

	deleteVersionControlForm = new FormGroup({
		versionControlId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addVersionControlForm.controls;
	}

	versionControlList: any;
	versionControlById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		version_nameError : "",
		descriptionError : "",
		version_codeError : "",
		force_updateError : "",
		recommended_updateError : "",
		statusError : "",
	};

	constructor(
		private versionControlService: VersionControlService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getVersionControl();
	}

	getVersionControl(): void {
		this.versionControlList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.versionControlService.getVersionControl(dataTablesParameters).subscribe(
				(data) => {
					that.versionControlList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openVersionControlAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openVersionControlEdit(content: any, versionControlId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.versionControlService.getVersionControlById(versionControlId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.versionControlById = data.data;
			this.updateVersionControlForm.controls["version_name"].setValue(data.data.version_name);
			this.updateVersionControlForm.controls["description"].setValue(data.data.description);
			this.updateVersionControlForm.controls["version_code"].setValue(data.data.version_code);
			this.updateVersionControlForm.controls["force_update"].setValue(data.data.force_update);
			this.updateVersionControlForm.controls["recommended_update"].setValue(data.data.recommended_update);
			this.updateVersionControlForm.controls["status"].setValue(data.data.status);
			this.updateVersionControlForm.controls["versionControlId"].setValue(data.data.id);
			} else {
			this.versionControlById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openVersionControlView(content: any, versionControlId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.versionControlService.getVersionControlById(versionControlId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.versionControlById = data.data;
			} else {
			this.versionControlById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openVersionControlDelete(content: any, versionControlId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteVersionControlForm.controls["versionControlId"].setValue(versionControlId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addVersionControl($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let version_name = this.addVersionControlForm.value.version_name.trim();
		let description = this.addVersionControlForm.value.description.trim();
		let version_code = this.addVersionControlForm.value.version_code.trim();
		let force_update = this.addVersionControlForm.value.force_update.trim();
		let recommended_update = this.addVersionControlForm.value.force_update.trim();
		let status = this.addVersionControlForm.value.status.trim();
		if (
		!version_name ||
		!description ||
		!version_code ||
		!force_update ||
		!recommended_update ||
		!status
		) {
		if (!version_name) {
			this.formErrorMessages.version_nameError = "Blood Bank version_name field is required.";
		} else {
			this.formErrorMessages.version_nameError = "";
		}
		if (!description) {
			this.formErrorMessages.descriptionError = "Blood Bank description field is required.";
		} else {
			this.formErrorMessages.descriptionError = "";
		}
		if (!version_code) {
			this.formErrorMessages.version_codeError = "Blood Bank version_code field is required.";
		} else {
			this.formErrorMessages.version_codeError = "";
		}
		if (!force_update) {
			this.formErrorMessages.force_updateError = "Blood Bank force_update field is required.";
		} else {
			this.formErrorMessages.force_updateError = "";
		}
		if (!recommended_update) {
			this.formErrorMessages.recommended_updateError = "Blood Bank recommended_update field is required.";
		} else {
			this.formErrorMessages.recommended_updateError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.versionControlService
		.addVersionControl({
			version_name,
			description,
			version_code,
			force_update,
			recommended_update,
			status
		} as VersionControl)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addVersionControlModalClose")?.click();
				this.addVersionControlForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateVersionControl($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let version_name = this.updateVersionControlForm.value.version_name.trim();
		let description = this.updateVersionControlForm.value.description.trim();
		let version_code = this.updateVersionControlForm.value.version_code.trim();
		let force_update = this.updateVersionControlForm.value.force_update.trim();
		let recommended_update = this.updateVersionControlForm.value.recommended_update.trim();
		let status = this.updateVersionControlForm.value.status.trim();
		let versionControlId = this.updateVersionControlForm.value.versionControlId;
		
		if (
		!version_name ||
		!description ||
		!version_code ||
		!force_update ||
		!recommended_update ||
		!status ||
		!versionControlId
		) {
		if (!version_name) {
			this.formErrorMessages.version_nameError = "VersionControl version_name field is required.";
		} else {
			this.formErrorMessages.version_nameError = "";
		}
		if (!description) {
			this.formErrorMessages.descriptionError = "Blood Bank description field is required.";
		} else {
			this.formErrorMessages.descriptionError = "";
		}
		if (!version_code) {
			this.formErrorMessages.version_codeError = "Blood Bank version_code field is required.";
		} else {
			this.formErrorMessages.version_codeError = "";
		}
		if (!force_update) {
			this.formErrorMessages.force_updateError = "Blood Bank force_update field is required.";
		} else {
			this.formErrorMessages.force_updateError = "";
		}
		if (!recommended_update) {
			this.formErrorMessages.recommended_updateError = "Blood Bank recommended_update field is required.";
		} else {
			this.formErrorMessages.recommended_updateError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.versionControlService
		.updateVersionControl({
			version_name,
			description,
			version_code,
			force_update,
			recommended_update,
			status,
			versionControlId
		} as VersionControl)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateVersionControlModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteVersionControl(): void {

		let versionControlId = this.deleteVersionControlForm.value.versionControlId;
		if (!versionControlId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.versionControlService.deleteVersionControl({ versionControlId } as VersionControl).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteVersionControlModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}