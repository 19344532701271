import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: [
		
	]
})
export class ForgetPasswordComponent implements OnInit {

	forgetPasswordForm = new FormGroup({
		email: new FormControl('', [Validators.required])
	});

	get f() {
		return this.forgetPasswordForm.controls;
	}

	users: User[] = [];
	errorMessage = '';
	successMessage = '';
	formErrorMessages = {
		emailError: ''
	};

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
	) { }

	ngOnInit(): void {
		var currentUser = localStorage.getItem('currentUser');
		if(currentUser){
			var userArr = JSON.parse(currentUser);
			if(userArr.data.role_id == '1'){
				this.router.navigate(['dashboard']);
			} else if(userArr.data.role_id == '2'){
				this.router.navigate(['a_dashboard']);
			}
		}
	}

	forgetPassword(): void {
		let email = this.forgetPasswordForm.value.email.trim();
		if (!email) {
			this.formErrorMessages.emailError = 'Email field is required.';
			this.errorMessage = 'Email field is required.';
			return;
		}
		this.authenticationService.forgetPassword({ email } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							this.router.navigate(['login']);
						}, 2000);
					} else {
						this.errorMessage = data.message;
					}
				},
				error => {
					this.errorMessage = error;
				}
			);
	}
}