import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BloodBankListRoutingModule } from "./blood-bank-list-routing.module";
import { BloodBankListComponent } from "./blood-bank-list.component";

@NgModule({
  imports: [
    CommonModule,
    BloodBankListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BloodBankListComponent],
  providers: [AuthGuard],
})
export class BloodBankListModule {}
