import { Component, OnInit } from "@angular/core";

import { UserService } from "../services/user.service";

@Component({
	selector: "app-role-list",
	templateUrl: "./role-list.component.html",
	styleUrls: ["./role-list.component.css"],
})
export class RoleListComponent implements OnInit {

	roleList: any;
	
	constructor(
		private userService: UserService,
	) {}

	ngOnInit(): void {
		this.getRole();
	}

  	getRole(): void {
		this.roleList = [];

		this.userService.getAllUserRole().subscribe(
		(data) => {
			if (data.status == 200) {
				this.roleList = data.data;
			} else {
				this.roleList = [];
			}
		},
		(error) => {
		}
		);
	}
}