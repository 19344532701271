<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Appointment Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card">
					<div class="card-header border-0 pt-6">
						<div class="card-title"></div>
						<div class="card-toolbar">
							<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base"></div>
						</div>
					</div>
					<div class="card-body py-4">
						<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions">
							<thead>
								<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
									<th>SR. No.</th>
									<th>User Name</th>
									<th>Date & Time</th>
									<th>Location</th>
									<th>Donor Name</th>
									<th>Status</th>
									<th>Created</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody class="text-gray-600 fw-semibold" *ngIf="appointmentList?.length != 0">
								<tr *ngFor="let appointment of appointmentList;let i = index">
									<td>{{i+1}}</td>
									<td>{{appointment.user_first_name}} {{appointment.user_last_name}}</td>
									<td>{{appointment.date}} {{appointment.time}}</td>
									<td>{{appointment.location}}</td>
									<td>{{appointment.donor_first_name}} {{appointment.donor_last_name}}</td>
									<td>{{appointment.status}}</td>
									<td>{{appointment.date_filter | date :'medium'}}</td>
									<td>
										<a href="javascript:;" (click)="openAppointmentView(viewAppointmentContent, appointment.id)" class="btn btn-sm btn-icon btn-active-color-primary">
											<i class="ki-outline ki-eye fs-2 text-primary">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a>
									</td>
								</tr>
							</tbody>
							<tbody class="text-gray-600 fw-semibold" *ngIf="appointmentList?.length == 0">
								<tr>
									<td>No Record Found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #viewAppointmentContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">View Appointment</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y">
		<form class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				<div class="table-responsive">
					<table class="table table-striped" style="line-height: 50px;;">
						<tr>
							<th class="text-right">User Name</th>
							<td>{{appointmentById.user_first_name}} {{appointmentById.user_last_name}}</td>
						</tr>
						<tr>
							<th class="text-right">Date & Time</th>
							<td>{{appointmentById.date}} {{appointmentById.time}}</td>
						</tr>
						<tr>
							<th class="text-right">Location</th>
							<td>{{appointmentById.location}}</td>
						</tr>
						<tr>
							<th class="text-right">Donor Name</th>
							<td>{{appointmentById.donor_first_name}} {{appointmentById.donor_last_name}}</td>
						</tr>
						<tr>
							<th class="text-right">Latitude</th>
							<td>{{appointmentById.latitude}}</td>
						</tr>
						<tr>
							<th class="text-right">Longitude</th>
							<td>{{appointmentById.longitude}}</td>
						</tr>
						<tr>
							<th class="text-right">Status</th>
							<td>{{appointmentById.status}}</td>
						</tr>
						<tr>
							<th class="text-right">Created</th>
							<td>{{appointmentById.created}}</td>
						</tr>
					</table>
					<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>