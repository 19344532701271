import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { VersionControl } from "../models/versionControl";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class VersionControlService {
	
	private getVersionControlUrl = environment.baseURL + "versionControl/getVersionControl";

	private getVersionControlByIdUrl = environment.baseURL + "versionControl/getVersionControlById";

	private addVersionControlUrl = environment.baseURL + "versionControl/createVersionControl";

	private updateVersionControlUrl = environment.baseURL + "versionControl/updateVersionControl";

	private deleteVersionControlUrl = environment.baseURL + "versionControl/deleteVersionControl";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getVersionControl(params: any): Observable<VersionControl> {
		return this.http
		.post<VersionControl>(this.getVersionControlUrl, params, this.httpOptions)
		.pipe(
			map((VersionControlList) => {
			return VersionControlList;
			})
		);
	}
	
	getVersionControlById(versionControlId: any): Observable<VersionControl> {
		let versionControlIds = { versionControlId: versionControlId };
		return this.http
		.post<VersionControl>(this.getVersionControlByIdUrl, versionControlIds, this.httpOptions)
		.pipe(
			map((VersionControlList) => {
			return VersionControlList;
			})
		);
	}

	addVersionControl(versionControl: VersionControl): Observable<VersionControl> {
		return this.http
		.post<VersionControl>(this.addVersionControlUrl, versionControl, this.httpOptions)
		.pipe(
			map((versionControl) => {
			return versionControl;
			})
		);
	}

	updateVersionControl(versionControl: VersionControl): Observable<VersionControl> {
		return this.http
		.post<VersionControl>(this.updateVersionControlUrl, versionControl, this.httpOptions)
		.pipe(
			map((versionControl) => {
			return versionControl;
			})
		);
	}

	deleteVersionControl(versionControl: VersionControl): Observable<VersionControl> {
		return this.http
		.post<VersionControl>(this.deleteVersionControlUrl, versionControl, this.httpOptions)
		.pipe(
			map((versionControl) => {
			return versionControl;
			})
		);
	}
}
