import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/user";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class UserService {
	
	private getAllAdminUrl = environment.baseURL + "user/getAllAdmin";
	
	private getAllUserUrl = environment.baseURL + "user/getAllUser";

	private getAllUserRoleUrl = environment.baseURL + "user/getAllUserRole";

	private getUserByIdUrl = environment.baseURL + "user/getUserById";

	private addUserUrl = environment.baseURL + "user/createUser";

	private updateUserUrl = environment.baseURL + "user/updateUser";

	private deleteUserUrl = environment.baseURL + "user/deleteUser";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}

	getAllAdmin(): Observable<User> {
		return this.http
		.get<User>(this.getAllAdminUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getAllUser(params: any): Observable<User> {
		return this.http
		.post<User>(this.getAllUserUrl, params, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getAllUserRole(): Observable<User> {
		return this.http
		.get<User>(this.getAllUserRoleUrl, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	getUserById(userId: any): Observable<User> {
		let userIds = { userId: userId };
		return this.http
		.post<User>(this.getUserByIdUrl, userIds, this.httpOptions)
		.pipe(
			map((userList) => {
			return userList;
			})
		);
	}

	addUser(user: User): Observable<User> {
		return this.http
		.post<User>(this.addUserUrl, user, this.httpOptions)
		.pipe(
			map((user) => {
			return user;
			})
		);
	}

	updateUser(user: User): Observable<User> {
		return this.http
		.post<User>(this.updateUserUrl, user, this.httpOptions)
		.pipe(
			map((user) => {
			return user;
			})
		);
	}

	deleteUser(user: User): Observable<User> {
		return this.http
		.post<User>(this.deleteUserUrl, user, this.httpOptions)
		.pipe(
			map((user) => {
			return user;
			})
		);
	}
}
