import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PathLab } from "../models/pathLab";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class PathLabService {
	
	private getPathLabUrl = environment.baseURL + "pathLab/getPathLab";

	private getPathLabByIdUrl = environment.baseURL + "pathLab/getPathLabById";

	private addPathLabUrl = environment.baseURL + "pathLab/createPathLab";

	private updatePathLabUrl = environment.baseURL + "pathLab/updatePathLab";

	private deletePathLabUrl = environment.baseURL + "pathLab/deletePathLab";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getPathLab(params: any): Observable<PathLab> {
		return this.http
		.post<PathLab>(this.getPathLabUrl, params, this.httpOptions)
		.pipe(
			map((pathLabList) => {
			return pathLabList;
			})
		);
	}
	
	getPathLabById(pathLabId: any): Observable<PathLab> {
		let pathLabIds = { pathLabId: pathLabId };
		return this.http
		.post<PathLab>(this.getPathLabByIdUrl, pathLabIds, this.httpOptions)
		.pipe(
			map((pathLabList) => {
			return pathLabList;
			})
		);
	}

	addPathLab(pathLab: PathLab): Observable<PathLab> {
		return this.http
		.post<PathLab>(this.addPathLabUrl, pathLab, this.httpOptions)
		.pipe(
			map((pathLab) => {
			return pathLab;
			})
		);
	}

	updatePathLab(pathLab: PathLab): Observable<PathLab> {
		return this.http
		.post<PathLab>(this.updatePathLabUrl, pathLab, this.httpOptions)
		.pipe(
			map((pathLab) => {
			return pathLab;
			})
		);
	}

	deletePathLab(pathLab: PathLab): Observable<PathLab> {
		return this.http
		.post<PathLab>(this.deletePathLabUrl, pathLab, this.httpOptions)
		.pipe(
			map((pathLab) => {
			return pathLab;
			})
		);
	}
}
