import { Component, OnInit } from "@angular/core";

import { UserService } from "../services/user.service";

@Component({
	selector: "app-user-list",
	templateUrl: "./user-list.component.html",
	styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
	dtOptions: DataTables.Settings = {};
  	
	userList: any;
	
	constructor(
		private userService: UserService,
	) {}

	ngOnInit(): void {
		this.getUser();
	}

  	getUser(): void {
		this.userList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {

				this.userService.getAllUser(dataTablesParameters).subscribe(
				(data) => {
					that.userList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "patient_name" }, { data: "created" }],
		};
	}
}