import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Appointment } from "../models/appointment";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class AppointmentService {
	
	private getAppointmentUrl = environment.baseURL + "appointment/getAppointment";

	private getAppointmentByIdUrl = environment.baseURL + "appointment/getAppointmentById";

	private getAppointmentByUserIdUrl = environment.baseURL + "appointment/getAppointmentByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getAppointment(params: any): Observable<Appointment> {
		return this.http
		.post<Appointment>(this.getAppointmentUrl, params, this.httpOptions)
		.pipe(
			map((AppointmentList) => {
			return AppointmentList;
			})
		);
	}
	
	getAppointmentById(appointmentId: any): Observable<Appointment> {
		let appointmentIds = { appointmentId: appointmentId };
		return this.http
		.post<Appointment>(this.getAppointmentByIdUrl, appointmentIds, this.httpOptions)
		.pipe(
			map((AppointmentList) => {
			return AppointmentList;
			})
		);
	}

	getAppointmentsByUserId(userId: any): Observable<Appointment> {
		let userIds = { userId: userId };

		return this.http
		.post<Appointment>(this.getAppointmentByUserIdUrl, userIds, this.httpOptions)
		.pipe(
			map((AppointmentList) => {
			return AppointmentList;
			})
		);
	}
}
