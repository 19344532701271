import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JwPaginationModule } from "jw-angular-pagination";

import { AuthGuard } from "./auth.guard";

import { LoginModule } from "./login/login.module";

import { LandingModule } from "./landing/landing.module";

import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";

import { TermsConditionModule } from "./terms-condition/terms-condition.module";

import { ForgetPasswordModule } from "./forget-password/forget-password.module";

import { DeleteAccountModule } from "./delete-account/delete-account.module";

import { DeleteAccountThankyouModule } from "./delete-account-thankyou/delete-account-thankyou.module";

import { VerifyOtpModule } from "./verify-otp/verify-otp.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { RoleListModule } from "./role-list/role-list.module";

import { UserListModule } from "./user-list/user-list.module";

import { UserViewModule } from "./user-view/user-view.module";

import { UserBloodRequestModule } from "./user-blood-request/user-blood-request.module";

import { UserAppointmentsModule } from "./user-appointments/user-appointments.module";

import { BloodBankListModule } from "./blood-bank-list/blood-bank-list.module";

import { HospitalListModule } from "./hospital-list/hospital-list.module";

import { PathLabListModule } from "./path-lab-list/path-lab-list.module";

import { BannerListModule } from "./banner-list/banner-list.module";

import { DiseaseListModule } from "./disease-list/disease-list.module";

import { BloodRequestListModule } from "./blood-request-list/blood-request-list.module";

import { BloodRequestDetailsModule } from "./blood-request-details/blood-request-details.module";

import { BloodTypeListModule } from "./blood-type-list/blood-type-list.module";

import { AppointmentListModule } from "./appointment-list/appointment-list.module";

import { CommunityListModule } from "./community-list/community-list.module";

import { CommunityDetailsModule } from "./community-details/community-details.module";

import { BadgeListModule } from "./badge-list/badge-list.module";

import { VersionControlListModule } from "./version-control-list/version-control-list.module";

import { ProfileModule } from "./profile/profile.module";

import { UpdateProfileModule } from "./update-profile/update-profile.module";

import { ChangePasswordModule } from "./change-password/change-password.module";

import { LogoutModule } from "./logout/logout.module";

import { BDashboardModule } from "./b-dashboard/b-dashboard.module";

import { BProfileModule } from "./b-profile/b-profile.module";

import { HDashboardModule } from "./h-dashboard/h-dashboard.module";

import { HProfileModule } from "./h-profile/h-profile.module";

import { PDashboardModule } from "./p-dashboard/p-dashboard.module";

import { PProfileModule } from "./p-profile/p-profile.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JwPaginationModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    DashboardModule,
    PrivacyPolicyModule,
    TermsConditionModule,
    LoginModule,
    LandingModule,
    UserListModule,
    UserViewModule,
    UserBloodRequestModule,
    UserAppointmentsModule,
    BloodBankListModule,
    HospitalListModule,
    PathLabListModule,
    BannerListModule,
    DiseaseListModule,
    BloodTypeListModule,
    BloodRequestListModule,
    BloodRequestDetailsModule,
    RoleListModule,
    ForgetPasswordModule,
    DeleteAccountModule,
    DeleteAccountThankyouModule,
    AppointmentListModule,
    CommunityListModule,
    CommunityDetailsModule,
    BadgeListModule,
    VersionControlListModule,
    VerifyOtpModule,
    ProfileModule,
    UpdateProfileModule,
    ChangePasswordModule,
    LogoutModule,
    BDashboardModule,
    BProfileModule,
    HDashboardModule,
    HProfileModule,
    PDashboardModule,
    PProfileModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
