import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BloodBank } from "../models/bloodBank";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class BloodBankService {
	
	private getBloodBankUrl = environment.baseURL + "bloodBank/getBloodBank";

	private getBloodBankByIdUrl = environment.baseURL + "bloodBank/getBloodBankById";

	private addBloodBankUrl = environment.baseURL + "bloodBank/createBloodBank";

	private updateBloodBankUrl = environment.baseURL + "bloodBank/updateBloodBank";

	private deleteBloodBankUrl = environment.baseURL + "bloodBank/deleteBloodBank";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getBloodBank(params: any): Observable<BloodBank> {
		return this.http
		.post<BloodBank>(this.getBloodBankUrl, params, this.httpOptions)
		.pipe(
			map((bloodBankList) => {
			return bloodBankList;
			})
		);
	}
	
	getBloodBankById(bloodBankId: any): Observable<BloodBank> {
		let bloodBankIds = { bloodBankId: bloodBankId };
		return this.http
		.post<BloodBank>(this.getBloodBankByIdUrl, bloodBankIds, this.httpOptions)
		.pipe(
			map((bloodBankList) => {
			return bloodBankList;
			})
		);
	}

	addBloodBank(bloodBank: BloodBank): Observable<BloodBank> {
		return this.http
		.post<BloodBank>(this.addBloodBankUrl, bloodBank, this.httpOptions)
		.pipe(
			map((bloodBank) => {
			return bloodBank;
			})
		);
	}

	updateBloodBank(bloodBank: BloodBank): Observable<BloodBank> {
		return this.http
		.post<BloodBank>(this.updateBloodBankUrl, bloodBank, this.httpOptions)
		.pipe(
			map((bloodBank) => {
			return bloodBank;
			})
		);
	}

	deleteBloodBank(bloodBank: BloodBank): Observable<BloodBank> {
		return this.http
		.post<BloodBank>(this.deleteBloodBankUrl, bloodBank, this.httpOptions)
		.pipe(
			map((bloodBank) => {
			return bloodBank;
			})
		);
	}
}
