import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-delete-account',
	templateUrl: './delete-account.component.html',
	styleUrls: [
		
	]
})
export class DeleteAccountComponent implements OnInit {

	deleteAccountForm = new FormGroup({
		phone: new FormControl('', [Validators.required])
	});

	get f() {
		return this.deleteAccountForm.controls;
	}

	users: User[] = [];
	errorMessage = '';
	successMessage = '';
	formErrorMessages = {
		phoneError: ''
	};

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
	) { }

	ngOnInit(): void {

	}

	deleteAccount(): void {
		let phone = this.deleteAccountForm.value.phone.trim();
		if (!phone) {
			this.formErrorMessages.phoneError = 'Phone field is required.';
			this.errorMessage = 'Phone field is required.';
			return;
		} else {
			this.formErrorMessages.phoneError = '';
		}
		this.authenticationService.deleteAccount({ phone } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						// if(data.data.role_type == '1'){
							localStorage.setItem('delete_phone', data.data.phone);
							setTimeout(() => {
								this.router.navigate(['verify_otp']);
							}, 2000);
						// } else{
						// 	this.errorMessage = 'No routing found for this Role.';
						// }
					} else {
						this.errorMessage = data.message;
					}
				},
				error => {
					this.errorMessage = error;
				}
			);
	}
}