<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div style="text-align: center;">
                        <img src="assets/media/avatars/logo.png" alt="" style="width: 15%;" /> 
                    </div>
                    <div class="w-lg-500px p-10">
                        <form class="form w-100">
                            <div class="text-center mb-11">
                                <h1 class="text-dark fw-bolder mb-3">Coming Soon</h1>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>