import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { DiseaseService } from "../services/disease.service";
import { Disease } from "../models/disease";

@Component({
	selector: "app-disease-list",
	templateUrl: "./disease-list.component.html",
	styleUrls: ["./disease-list.component.css"],
})
export class DiseaseListComponent implements OnInit {
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addDiseaseForm = new FormGroup({
		question: new FormControl("", [Validators.required]),
  	});

  	updateDiseaseForm = new FormGroup({
		question: new FormControl("", [Validators.required]),
		diseaseId: new FormControl("", [Validators.required]),
  	});

	deleteDiseaseForm = new FormGroup({
		diseaseId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addDiseaseForm.controls;
	}

	diseaseList: any;
	diseaseById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		questionError : ""
	};

	constructor(
		private diseaseService: DiseaseService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getDisease();
	}

  	getDisease(): void {
		this.diseaseList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.diseaseService.getDisease(dataTablesParameters).subscribe(
				(data) => {
					that.diseaseList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "url" }, { data: "created" }],
		};
	}

	openDiseaseAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDiseaseEdit(content: any, diseaseId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.diseaseService.getDiseaseById(diseaseId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.diseaseById = data.data;
			this.updateDiseaseForm.controls["question"].setValue(data.data.question);
			this.updateDiseaseForm.controls["diseaseId"].setValue(data.data.id);
			} else {
			this.diseaseById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDiseaseView(content: any, diseaseId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.diseaseService.getDiseaseById(diseaseId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.diseaseById = data.data;
			} else {
			this.diseaseById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDiseaseDelete(content: any, diseaseId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteDiseaseForm.controls["diseaseId"].setValue(diseaseId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addDisease($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let question = this.addDiseaseForm.value.question.trim();
		if (
		!question
		) {
		if (!question) {
			this.formErrorMessages.questionError = "Banner question field is required.";
		} else {
			this.formErrorMessages.questionError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.diseaseService
		.addDisease({
			question
		} as Disease)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addBannerModalClose")?.click();
				this.addDiseaseForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateDisease($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let question = this.updateDiseaseForm.value.question.trim();
		let diseaseId = this.updateDiseaseForm.value.diseaseId;
		if (
		!question ||
		!diseaseId
		) {
		if (!question) {
			this.formErrorMessages.questionError = "Banner question field is required.";
		} else {
			this.formErrorMessages.questionError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.diseaseService
		.updateDisease({
			question,
			diseaseId
		} as Disease)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateBannerModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteDisease(): void {

		let diseaseId = this.deleteDiseaseForm.value.diseaseId;
		if (!diseaseId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.diseaseService.deleteDisease({ diseaseId } as Disease).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteBannerModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}