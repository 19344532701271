import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-h-dashboard',
	templateUrl: './h-dashboard.component.html',
	styleUrls: [
		'./h-dashboard.component.css'
	]
})
export class HDashboardComponent implements OnInit {
	
	constructor(
		private dashboardService: DashboardService,
	) {}

	ngOnInit() {
	}
}