import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-p-profile',
	templateUrl: './p-profile.component.html',
	styleUrls: [
		'./p-profile.component.css'
	]
})
export class PProfileComponent implements OnInit {
	
	constructor(
		private dashboardService: DashboardService,
	) {}

	ngOnInit() {
	}
}