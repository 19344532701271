import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-h-profile',
	templateUrl: './h-profile.component.html',
	styleUrls: [
		'./h-profile.component.css'
	]
})
export class HProfileComponent implements OnInit {
	
	constructor(
		private dashboardService: DashboardService,
	) {}

	ngOnInit() {
	}
}