import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	private loginUrl = environment.baseURL + 'auth/login';
	private forgetPasswordUrl = environment.baseURL + 'auth/forgetPassword';
	private deleteAccountUrl = environment.baseURL + 'auth/deleteAccount';
	private verifyOtpUrl = environment.baseURL + 'auth/verifyOtp';
	
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(private http: HttpClient) { }

	login(user: User): Observable<User> {
		return this.http.post<User>(this.loginUrl, user, this.httpOptions).pipe(map(user => {
			localStorage.setItem('currentUser', JSON.stringify(user));
			return user;
		})
		);
	}

	forgetPassword(user: User): Observable<User> {
		return this.http.post<User>(this.forgetPasswordUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	deleteAccount(user: User): Observable<User> {
		return this.http.post<User>(this.deleteAccountUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	verifyOtp(user: User): Observable<User> {
		return this.http.post<User>(this.verifyOtpUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}
}
