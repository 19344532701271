import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BProfileComponent } from './b-profile.component';

import { AuthGuard } from '../auth.guard';
import { AuthBankGuard } from '../authbank.guard';

const routes: Routes = [
  {
    path: 'b_profile',
    canActivate: [AuthGuard, AuthBankGuard],
    component: BProfileComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BProfileRoutingModule { }
