import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { HospitalListRoutingModule } from "./hospital-list-routing.module";
import { HospitalListComponent } from "./hospital-list.component";

@NgModule({
  imports: [
    CommonModule,
    HospitalListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [HospitalListComponent],
  providers: [AuthGuard],
})
export class HospitalListModule {}
