import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router
	) { }
	canActivate() {
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			return true;
		}
		this.router.navigate(['']);
		return false;
	}
}
