<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Dashboard</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="row g-5 g-xl-8">
					<div class="col-xl-4">
						<a href="user_list" class="card bg-body-white hoverable card-xl-stretch mb-xl-8">
							<div class="card-body">
								<div class="fw-semibold fs-2 text-gray-900" style="font-size: 15px !important;">Total Users: {{userCounter.total_users}}</div><br>
								<div class="fw-semibold fs-2 text-gray-900" style="font-size: 15px !important;"></div><br>
								<div class="fw-semibold fs-2 text-gray-900" style="font-size: 15px !important;"></div>
							</div>
						</a>
					</div>
					<div class="col-xl-4">
						<a href="blood_request_list" class="card bg-primary hoverable card-xl-stretch mb-xl-8">
							<div class="card-body">
								<div class="fw-semibold fs-2 text-white" style="font-size: 15px !important;">Total Blood Requests: {{bloodCounter.total_blood}}</div><br>
								<div class="fw-semibold fs-2 text-white" style="font-size: 15px !important;">Active Blood Requests: {{bloodCounter.active_blood}}</div><br>
								<div class="fw-semibold fs-2 text-white" style="font-size: 15px !important;">Completed Blood Requests: {{bloodCounter.complete_blood}}</div>
							</div>
						</a>
					</div>
					<div class="col-xl-4">
						<a href="appointment_list" class="card bg-dark hoverable card-xl-stretch mb-5 mb-xl-8">
							<div class="card-body">
								<div class="fw-semibold fs-2 text-gray-100" style="font-size: 15px !important;">Total Appointments: {{appointmentCounter.total_appointment}}</div><br>
								<div class="fw-semibold fs-2 text-gray-100" style="font-size: 15px !important;">Active Appointments: {{appointmentCounter.active_appointment}}</div><br>
								<div class="fw-semibold fs-2 text-gray-100" style="font-size: 15px !important;">Completed Appointments: {{appointmentCounter.complete_appointment}}</div>
							</div>
						</a>
					</div>
				</div>
				<div class="row g-5 g-xl-8">
					<div class="col-xl-12">
						<div class="card card-xl-stretch mb-5 mb-xl-8">
							<div class="card-header border-0 pt-5">
								<h3 class="card-title align-items-start flex-column">
									<span class="card-label fw-bold fs-3 mb-1">Latest Blood Requests</span>
									<span class="text-muted mt-1 fw-semibold fs-7">More than 400 new blood requests</span>
								</h3>
							</div>
							<div class="card-body py-3">
								<div class="tab-content">
									<div class="tab-pane fade show active" id="kt_table_widget_5_tab_1">
										<div class="table-responsive">
											<table class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
												<thead>
													<tr class="border-0">
														<th class="p-0 min-w-100px"></th>
														<th class="p-0 min-w-80px"></th>
														<th class="p-0 min-w-80px"></th>
														<th class="p-0 min-w-80px"></th>
														<th class="p-0 min-w-30px"></th>
														<th class="p-0 min-w-80px"></th>
														<th class="p-0 min-w-50px"></th>
													</tr>
												</thead>
												<tbody *ngIf="bloodList?.length != 0">
													<tr *ngFor="let blood of bloodList;let i = index">
														<td>
															<a href="javascript:;" class="text-dark fw-bold text-hover-primary mb-1 fs-6">{{blood.patient_name}}</a>
															<span class="text-muted fw-semibold d-block">{{blood.phone_number}}</span>
														</td>
														<td>
															<a href="javascript:;" class="text-dark fw-bold text-hover-primary mb-1 fs-6">{{blood.blood_group}}</a>
															<span class="text-muted fw-semibold d-block">{{blood.blood_type}}</span>
														</td>
														<td class="text-muted fw-bold">{{blood.required_date | date :'mediumDate'}}</td>
														<td>
															<a href="javascript:;" class="text-dark fw-bold text-hover-primary mb-1 fs-6">Units: {{blood.units}}</a><br>
															<span class="badge badge-light-danger" *ngIf="blood.is_critical">Critical</span>
														</td>
														<td class="">
															<span class="badge badge-light-success">{{blood.status == '1' ? 'Active' : 'Inactive'}}</span>
														</td>
														<td class="text-muted fw-bold">{{blood.date_filter}}</td>
														<td class="text-end">
															<a href="blood_request_details/{{blood.id}}" class="btn btn-sm btn-icon btn-active-color-primary">
																<i class="ki-outline ki-eye fs-2 text-primary">
																	<span class="path1"></span>
																	<span class="path2"></span>
																</i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row g-5 g-xl-8">
					<div class="col-xl-12">
						<div class="card card-xl-stretch mb-5 mb-xl-8">
							<div class="card-header border-0 pt-5">
								<h3 class="card-title align-items-start flex-column">
									<span class="card-label fw-bold fs-3 mb-1">Latest Users</span>
								</h3>
							</div>
							<div class="card-body py-3">
								<div class="tab-content">
									<div class="tab-pane fade show active" id="kt_table_widget_5_tab_1">
										<div class="table-responsive">
											<table class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
												<thead>
													<tr class="border-0">
														<th class="p-0 w-50px"></th>
														<th class="p-0 min-w-100px"></th>
														<th class="p-0 min-w-140px"></th>
														<th class="p-0 min-w-110px"></th>
														<th class="p-0 min-w-50px"></th>
													</tr>
												</thead>
												<tbody *ngIf="userList?.length != 0">
													<tr *ngFor="let user of userList;let i = index">
														<td>
															<div class="symbol symbol-45px me-2">
																<span class="symbol-label">
																	<img src="{{user.profile_picture}}" class="h-50 align-self-center" alt="">
																</span>
															</div>
														</td>
														<td>
															<a href="javascript:;" class="text-dark fw-bold text-hover-primary mb-1 fs-6">{{user.first_name}} {{user.last_name}}</a>
															<span class="text-muted fw-semibold d-block">{{user.user_name}}</span>
														</td>
														<td>
															<a href="javascript:;" class="text-dark fw-bold text-hover-primary mb-1 fs-6">{{user.phone}}</a>
															<span class="text-muted fw-semibold d-block">{{user.email}}</span>
														</td>
														<td class="text-end text-muted fw-bold">{{user.blood_group}}</td>
														<td class="text-end">
															<span class="badge badge-light-success">{{user.status == '1' ? 'Active' : 'Inactive'}}</span>
														</td>
														<td class="text-end text-muted fw-bold">{{user.date_filter}}</td>
														<td class="text-end">
															<a href="user_view/{{user.id}}" class="btn btn-sm btn-icon btn-active-color-primary">
																<i class="ki-outline ki-eye fs-2 text-primary">
																	<span class="path1"></span>
																	<span class="path2"></span>
																</i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>