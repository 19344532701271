import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BloodRequestListRoutingModule } from "./blood-request-list-routing.module";
import { BloodRequestListComponent } from "./blood-request-list.component";

@NgModule({
  imports: [
    CommonModule,
    BloodRequestListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BloodRequestListComponent],
  providers: [AuthGuard],
})
export class BloodRequestListModule {}
