import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { HProfileRoutingModule } from './h-profile-routing.module';
import { HProfileComponent } from './h-profile.component';

@NgModule({
  imports: [
    CommonModule,
    HProfileRoutingModule
  ],
  declarations: [HProfileComponent],
  providers: [AuthGuard]
})
export class HProfileModule { }
