import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { BDashboardRoutingModule } from './b-dashboard-routing.module';
import { BDashboardComponent } from './b-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    BDashboardRoutingModule
  ],
  declarations: [BDashboardComponent],
  providers: [AuthGuard]
})
export class BDashboardModule { }
