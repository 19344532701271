import { Component, OnInit } from "@angular/core";

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: "app-blood-request-list",
	templateUrl: "./blood-request-list.component.html",
	styleUrls: ["./blood-request-list.component.css"],
})
export class BloodRequestListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};

	bloodRequestList: any;
	
	constructor(
		private bloodRequestService: BloodRequestService,
	) {}

	ngOnInit(): void {
		this.getBloodRequest();
	}

	getBloodRequest(): void {
		this.bloodRequestList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bloodRequestService.getBloodRequest(dataTablesParameters).subscribe(
				(data) => {
					that.bloodRequestList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "patient_name" }, { data: "created" }],
		};
	}
}
