<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="">
                        <div style="text-align: center;">
                            <img src="assets/media/avatars/logo.png" alt="" style="width: 15%;" /> 
                        </div>
                        <h1>Terms & Conditions</h1>
                        <p>Last updated: February 06, 2024</p>

                        <h3>1. Blood Donation Feature Terms :</h3>
                        <p>A) DonorZilla's blood donation feature ("Feature") aims to connect volunteer blood donors ("Donors") with blood Receivers in their nearest local geographical area that are seeking blood donors ("Receivers"). DonorZilla Connect Donors with people in the Donors’ geographical area ("Receivers"). Here are more details about the terms of service for the Feature (the "Terms") upon which Donors, Receivers and their relevant authorized Third Parties may use the Feature.</p>

                        <h3>2. DonorZilla is not a blood bank :</h3>
                        <p>A) DonorZilla is not a blood bank and DonorZilla is not affiliated with any particular blood recipient. DonorZilla does not carry out nor interfere with any business or activities in relation to blood donation, including but not limited to recruitment or selection of donors, collection, storage, processing or transportation of blood.</p>
                        <p>B) DonorZilla does not provide any medical supervision or assistance.</p>
                        <p>C) DonorZilla has not obtained, nor is it required to obtain, any statutory licenses, approvals, registration, certifications, or accreditations in this respect, including pursuant to any law, rules, or regulations applicable in the Blood Bank’s or Donor’s jurisdictions ("Applicable Law").</p>
                        
                        <h3>3. DonorZilla's goal is to facilitate the donor and the receiver with the help of enabling Technology for blood donation :</h3>
                        <p>A) Blood donation is a voluntary service and DonorZilla does not guarantee that a potential Donor will agree to donate blood whenever called upon to do so. Donors may remove their status as a blood donor at any time.</p>
                        <p>B) Blood donors have the right to stop participating in this Feature at any time.</p>
                        <p>C) DonorZilla does not guarantee that it will, or is able to, connect Donors and Receivers.</p>
                        <p>D) DonorZilla will not be liable for any injury or loss of life resulting from any connections or lack of connections facilitated through the Feature.</p>
                        <p>E) No contract, express or implied, is created hereby among any parties using the Feature, or with DonorZilla.</p>
                        
                        <h3>4. DonorZilla serves only as an Platform Provider to Connect Donors and Receivers with their smart App :</h3>
                        <p>A) DonorZilla only facilitates contact between Donors and Receivers in need of blood providing relevant information about donors and those in need of blood to potential Donors via the DonorZilla Application.</p>
                        <p>B) DonorZilla is not responsible for the direct contact made between Donors and receivers nor is DonorZilla responsible for the Donors’ actions before or after contact is made.</p>
                        <p>C) DonorZilla is not responsible for any misuse of contact information displayed. DonorZilla gives no assurance with respect to the authenticity, accuracy or correctness of the information provided by the Donoror requests made by the receivers.</p>
                        <p>D) The Donor and the Receiver are responsible for verifying the authenticity and correctness of the information provided by the other working with their respective hospitals or blood banks.</p>
                        <p>E) DonorZilla does not receive any protected health information.</p>
                        <p>F) Under no circumstances will DonorZilla have access to any Donor’s health information or records obtained from or arising out of any eligibility exams or other procedures performed by the health care providers</p>
                        <p>G) DonorZilla will not, to the extent permitted under Applicable Law, be responsible for any claims, disputes, suits, actions, proceedings, losses, injury, damages or harm (including, but not limited to, loss of personal data, personal injury, or loss of life) caused to the Donor, the receiver, and/or any third party due to any interaction between the Donor, Blood receiver, and/or any third party.</p>

                        <h3>5. DonorZilla does not accept money or incentives from Donors or Receivers for Donating Blood :</h3>
                        <p>A) DonorZilla does not charge any money from Donors or Receivers for their voluntary blood donation.</p>
                        
                        <h3>6. DonorZilla does not endorse or promote :</h3>
                        <p>A) When providing the Feature, DonorZilla is not promoting any treatment, drugs, services, remedies, or activities that claim to have powers to cure, diagnose, prevent, or mitigate any disease or other illness.</p>

                        <h3>7. Use of the Feature is subject to DonorZilla’s other terms and conditions :</h3>
                        <p>A) While using the Feature, the Donors and Receivers are also subject to DonorZilla’s Standards, and Data Policy, and other terms and conditions ("DonorZilla Website Terms") as may be applicable to the use of DonorZilla’s brands, products, and services. In case of any conflict or inconsistencies between these Blood Donation Feature Terms and other DonorZilla Website Terms, the latter shall prevail.</p>
                        <p>B) You understand that the collection, processing, storage and transfer of information obtained by DonorZilla in relation to your use of the Feature is in accordance with DonorZilla’s Data Policy. Any information or content provided in the Feature should be used by you only for the purposes set out in these Terms.</p>
                        <p>C) DonorZilla reserves the right to deny or terminate any Donors participation in or access to the Feature or to remove the Feature for any reason at any time.</p>

                        <h3>8. Donor Specific Terms :</h3>
                        <p>A) When you register as a Donor on DonorZilla, you express an interest to donate blood voluntarily and agree to receive updates when Receivers raise a request in your geographical area are looking for blood donors using our platform.</p>

                        <h3>9) Your use of the Feature :</h3>
                        <p>A) Blood donation is a voluntary act and it is entirely at your sole discretion to donate blood. You may remove your status as a blood donor and stop receiving updates at any time through your DonorZilla profile.</p>

                        <h3>10. DonorZilla is not a medical provider and does not provide medical advice :</h3>
                        <p>A) You should seek and obtain appropriate medical advice from qualified medical professionals prior to participating in any blood donation activities. DonorZilla does not conduct any independent health or background checks.</p>
                        <p>B) DonorZilla does not guarantee that you will be eligible or able to donate blood. The Receiver or the Third Party like Hospitals /Blood Banks , Diagnostic Laboratories are responsible for taking all steps required to determine your eligibility ,accuracy and ability to donate blood.</p>

                        <h3>11. DonorZilla is not responsible for the Blood Bank’s activities or operations :</h3>
                        <p>A) DonorZilla is not responsible for assessing the licensure, accreditation, or reputation of a Blood Bank. Donors are solely responsible for assessing the fitness of a Blood Banks prior to donating blood.</p>
                        <p>B) DonorZilla is not responsible for any information, collected, created by or about the Blood Bank.</p>
                        <p>C) Donors should review local regulations and policy to confirm that they are complying with Applicable Law.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>