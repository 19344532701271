import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { HospitalService } from "../services/hospital.service";
import { Hospital } from "../models/hospital";

@Component({
	selector: "app-hospital-list",
	templateUrl: "./hospital-list.component.html",
	styleUrls: ["./hospital-list.component.css"],
})
export class HospitalListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addHospitalForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.required]),
		c_name: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
		city: new FormControl("", [Validators.required]),
		state: new FormControl("", [Validators.required]),
		country: new FormControl("", [Validators.required]),
		latitude: new FormControl("", [Validators.required]),
		longitude: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
  	});

  	updateHospitalForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		phone: new FormControl("", [Validators.required]),
		c_name: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
		city: new FormControl("", [Validators.required]),
		state: new FormControl("", [Validators.required]),
		country: new FormControl("", [Validators.required]),
		latitude: new FormControl("", [Validators.required]),
		longitude: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		hospitalId: new FormControl("", [Validators.required]),
  	});

	deleteHospitalForm = new FormGroup({
		hospitalId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addHospitalForm.controls;
	}

	hospitalList: any;
	hospitalById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		nameError : "",
		phoneError : "",
		c_nameError : "",
		c_phoneError : "",
		c_emailError : "",
		e_phoneError : "",
		addressError : "",
		cityError : "",
		stateError : "",
		countryError : "",
		latitudeError : "",
		longitudeError : "",
		statusError : "",
	};

	constructor(
		private hospitalService: HospitalService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getHospital();
	}

	getHospital(): void {
		this.hospitalList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.hospitalService.getHospital(dataTablesParameters).subscribe(
				(data) => {
					that.hospitalList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openHospitalAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openHospitalEdit(content: any, hospitalId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.hospitalService.getHospitalById(hospitalId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.hospitalById = data.data;
			this.updateHospitalForm.controls["name"].setValue(data.data.name);
			this.updateHospitalForm.controls["phone"].setValue(data.data.phone);
			this.updateHospitalForm.controls["c_name"].setValue(data.data.c_name);
			this.updateHospitalForm.controls["c_phone"].setValue(data.data.c_phone);
			this.updateHospitalForm.controls["c_email"].setValue(data.data.c_email);
			this.updateHospitalForm.controls["e_phone"].setValue(data.data.e_phone);
			this.updateHospitalForm.controls["address"].setValue(data.data.address);
			this.updateHospitalForm.controls["city"].setValue(data.data.city);
			this.updateHospitalForm.controls["state"].setValue(data.data.state);
			this.updateHospitalForm.controls["country"].setValue(data.data.country);
			this.updateHospitalForm.controls["latitude"].setValue(data.data.latitude);
			this.updateHospitalForm.controls["longitude"].setValue(data.data.longitude);
			this.updateHospitalForm.controls["status"].setValue(data.data.status);
			this.updateHospitalForm.controls["hospitalId"].setValue(data.data.id);
			} else {
			this.hospitalById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openHospitalView(content: any, hospitalId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.hospitalService.getHospitalById(hospitalId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.hospitalById = data.data;
			} else {
			this.hospitalById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openHospitalDelete(content: any, hospitalId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteHospitalForm.controls["hospitalId"].setValue(hospitalId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addHospital($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.addHospitalForm.value.name.trim();
		let phone = this.addHospitalForm.value.phone.trim();
		let c_name = this.addHospitalForm.value.c_name.trim();
		let c_phone = this.addHospitalForm.value.c_phone.trim();
		let c_email = this.addHospitalForm.value.c_email.trim();
		let e_phone = this.addHospitalForm.value.e_phone.trim();
		let address = this.addHospitalForm.value.address.trim();
		let city = this.addHospitalForm.value.city.trim();
		let state = this.addHospitalForm.value.state.trim();
		let country = this.addHospitalForm.value.country.trim();
		let latitude = this.addHospitalForm.value.latitude.trim();
		let longitude = this.addHospitalForm.value.longitude.trim();
		let status = this.addHospitalForm.value.status.trim();
		if (
		!name ||
		!phone ||
		!c_name ||
		!c_phone ||
		!c_email ||
		!e_phone ||
		!address ||
		!city ||
		!state ||
		!country ||
		!latitude ||
		!longitude ||
		!status
		) {
		if (!name) {
			this.formErrorMessages.nameError = "Blood Bank name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!phone) {
			this.formErrorMessages.phoneError = "Blood Bank phone field is required.";
		} else {
			this.formErrorMessages.phoneError = "";
		}
		if (!c_name) {
			this.formErrorMessages.c_nameError = "Blood Bank c_name field is required.";
		} else {
			this.formErrorMessages.c_nameError = "";
		}
		if (!c_phone) {
			this.formErrorMessages.c_phoneError = "Blood Bank c_phone field is required.";
		} else {
			this.formErrorMessages.c_phoneError = "";
		}
		if (!c_email) {
			this.formErrorMessages.c_emailError = "Blood Bank c_email field is required.";
		} else {
			this.formErrorMessages.c_emailError = "";
		}
		if (!e_phone) {
			this.formErrorMessages.e_phoneError = "Blood Bank e_phone field is required.";
		} else {
			this.formErrorMessages.e_phoneError = "";
		}
		if (!address) {
			this.formErrorMessages.addressError = "Blood Bank address field is required.";
		} else {
			this.formErrorMessages.addressError = "";
		}
		if (!city) {
			this.formErrorMessages.cityError = "Blood Bank city field is required.";
		} else {
			this.formErrorMessages.cityError = "";
		}
		if (!state) {
			this.formErrorMessages.stateError = "Blood Bank state field is required.";
		} else {
			this.formErrorMessages.stateError = "";
		}
		if (!country) {
			this.formErrorMessages.countryError = "Blood Bank country field is required.";
		} else {
			this.formErrorMessages.countryError = "";
		}
		if (!latitude) {
			this.formErrorMessages.latitudeError = "Blood Bank latitude field is required.";
		} else {
			this.formErrorMessages.latitudeError = "";
		}
		if (!longitude) {
			this.formErrorMessages.longitudeError = "Blood Bank longitude field is required.";
		} else {
			this.formErrorMessages.longitudeError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.hospitalService
		.addHospital({
			name,
			phone,
			c_name,
			c_phone,
			c_email,
			e_phone,
			address,
			city,
			state,
			country,
			latitude,
			longitude,
			status
		} as Hospital)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addHospitalModalClose")?.click();
				this.addHospitalForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateHospital($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.updateHospitalForm.value.name.trim();
		let phone = this.updateHospitalForm.value.phone.trim();
		let c_name = this.updateHospitalForm.value.c_name.trim();
		let c_phone = this.updateHospitalForm.value.c_phone.trim();
		let c_email = this.updateHospitalForm.value.c_email.trim();
		let e_phone = this.updateHospitalForm.value.e_phone.trim();
		let address = this.updateHospitalForm.value.address.trim();
		let city = this.updateHospitalForm.value.city.trim();
		let state = this.updateHospitalForm.value.state.trim();
		let country = this.updateHospitalForm.value.country.trim();
		let latitude = this.updateHospitalForm.value.latitude.trim();
		let longitude = this.updateHospitalForm.value.longitude.trim();
		let status = this.updateHospitalForm.value.status.trim();
		let hospitalId = this.updateHospitalForm.value.hospitalId;
		
		if (
		!name ||
		!phone ||
		!c_name ||
		!c_phone ||
		!c_email ||
		!e_phone ||
		!address ||
		!city ||
		!state ||
		!country ||
		!latitude ||
		!longitude ||
		!status ||
		!hospitalId
		) {
		if (!name) {
			this.formErrorMessages.nameError = "Hospital name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!phone) {
			this.formErrorMessages.phoneError = "Blood Bank phone field is required.";
		} else {
			this.formErrorMessages.phoneError = "";
		}
		if (!c_name) {
			this.formErrorMessages.c_nameError = "Blood Bank c_name field is required.";
		} else {
			this.formErrorMessages.c_nameError = "";
		}
		if (!c_phone) {
			this.formErrorMessages.c_phoneError = "Blood Bank c_phone field is required.";
		} else {
			this.formErrorMessages.c_phoneError = "";
		}
		if (!c_email) {
			this.formErrorMessages.c_emailError = "Blood Bank c_email field is required.";
		} else {
			this.formErrorMessages.c_emailError = "";
		}
		if (!e_phone) {
			this.formErrorMessages.e_phoneError = "Blood Bank e_phone field is required.";
		} else {
			this.formErrorMessages.e_phoneError = "";
		}
		if (!address) {
			this.formErrorMessages.addressError = "Blood Bank address field is required.";
		} else {
			this.formErrorMessages.addressError = "";
		}
		if (!city) {
			this.formErrorMessages.cityError = "Blood Bank city field is required.";
		} else {
			this.formErrorMessages.cityError = "";
		}
		if (!state) {
			this.formErrorMessages.stateError = "Blood Bank state field is required.";
		} else {
			this.formErrorMessages.stateError = "";
		}
		if (!country) {
			this.formErrorMessages.countryError = "Blood Bank country field is required.";
		} else {
			this.formErrorMessages.countryError = "";
		}
		if (!latitude) {
			this.formErrorMessages.latitudeError = "Blood Bank latitude field is required.";
		} else {
			this.formErrorMessages.latitudeError = "";
		}
		if (!longitude) {
			this.formErrorMessages.longitudeError = "Blood Bank longitude field is required.";
		} else {
			this.formErrorMessages.longitudeError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.hospitalService
		.updateHospital({
			name,
			phone,
			c_name,
			c_phone,
			c_email,
			e_phone,
			address,
			city,
			state,
			country,
			latitude,
			longitude,
			status,
			hospitalId
		} as Hospital)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateHospitalModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteHospital(): void {

		let hospitalId = this.deleteHospitalForm.value.hospitalId;
		if (!hospitalId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.hospitalService.deleteHospital({ hospitalId } as Hospital).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteHospitalModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}