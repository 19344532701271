import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PProfileComponent } from './p-profile.component';

import { AuthGuard } from '../auth.guard';
import { AuthPathLabGuard } from '../authpathlab.guard';

const routes: Routes = [
  {
    path: 'p_profile',
    canActivate: [AuthGuard, AuthPathLabGuard],
    component: PProfileComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PProfileRoutingModule { }
