import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { VersionControlListRoutingModule } from "./version-control-list-routing.module";
import { VersionControlListComponent } from "./version-control-list.component";

@NgModule({
  imports: [
    CommonModule,
    VersionControlListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [VersionControlListComponent],
  providers: [AuthGuard],
})
export class VersionControlListModule {}
