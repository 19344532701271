import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { CommunityDetailsRoutingModule } from "./community-details-routing.module";
import { CommunityDetailsComponent } from "./community-details.component";

@NgModule({
  imports: [
    CommonModule,
    CommunityDetailsRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [CommunityDetailsComponent],
  providers: [AuthGuard],
})
export class CommunityDetailsModule {}
