import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-verify-otp',
	templateUrl: './verify-otp.component.html',
	styleUrls: [
		
	]
})
export class VerifyOtpComponent implements OnInit {

	verifyOtpForm = new FormGroup({
		otp: new FormControl('', [Validators.required])
	});

	confirmDeleteForm = new FormGroup({
		otp: new FormControl('', [Validators.required])
	});

	get f() {
		return this.verifyOtpForm.controls;
	}

	users: User[] = [];
	errorMessage = '';
	successMessage = '';
	formErrorMessages = {
		otpError: ''
	};

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		const phone = localStorage.getItem('delete_phone');
		if(!phone){
			this.errorMessage = 'You are not authorized to view this page';
			setTimeout(() => {
				this.router.navigate(['delete_account']);
			}, 2000);
		}
	}

	verifyOtp(): void {
		const phone = localStorage.getItem('delete_phone');
		if(phone){
			let otp = this.verifyOtpForm.value.otp.trim();
			if (!otp) {
				this.formErrorMessages.otpError = 'OTP field is required.';
				this.errorMessage = 'OTP field is required.';
				return;
			} else {
				this.formErrorMessages.otpError = '';
			}
			this.authenticationService.verifyOtp({ otp, phone } as User)
				.subscribe(
					data => {
						this.errorMessage = '';
						if (data.status == 200) {
							this.successMessage = data.message;
							localStorage.removeItem("delete_phone");
							let element: HTMLElement = document.querySelector('button[id="openModalButton"]') as HTMLElement;
							element.click();
						} else {
							this.errorMessage = data.message;
						}
					},
					error => {
						this.errorMessage = error;
					}
				);
		} else{
			this.errorMessage = 'You are not authorized to view this page';
			setTimeout(() => {
				this.router.navigate(['delete_account']);
			}, 2000);
		}		
	}

	openConfirmDelete(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'md', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			//this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	confirmDelete($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		this.successMessage = 'Your request for delete account has been submitted successfully.';
		setTimeout(() => {
			document.getElementById("addBannerModalClose")?.click();
			this.router.navigate(['delete_account_thankyou']);
		}, 3000);
	}
}