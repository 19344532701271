import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrivacyPolicyComponent } from './privacy-policy.component';

const routes: Routes = [
  {
    path: 'privacy_policy',
    component: PrivacyPolicyComponent,
    data: { showHeader: false, showSidebar: false, showFooter: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyPolicyRoutingModule { }
