import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { AppointmentService } from "../services/appointment.service";

@Component({
	selector: "app-appointment-list",
	templateUrl: "./appointment-list.component.html",
	styleUrls: ["./appointment-list.component.css"],
})
export class AppointmentListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";

	appointmentList: any;
	appointmentById: any;
	
	errorMessage = "";
	successMessage = "";
	
	constructor(
		private appointmentService: AppointmentService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getAppointment();
	}

	getAppointment(): void {
		this.appointmentList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.appointmentService.getAppointment(dataTablesParameters).subscribe(
				(data) => {
					that.appointmentList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openAppointmentView(content: any, appointmentId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.appointmentService.getAppointmentById(appointmentId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.appointmentById = data.data;
			} else {
			this.appointmentById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}