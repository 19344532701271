<style>
    .flex-column-fluid {
        flex: unset;
    }
</style>
<body id="kt_body" class="auth-bg bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat">
    <div class="d-flex flex-column flex-root">
        <style>body { background-image: url('assets/new_assets/media/auth/bg4.jpg'); } [data-bs-theme="dark"] body { background-image: url('assets/new_assets/media/auth/bg4-dark.jpg'); }</style>
        <div class="d-flex flex-column flex-column-fluid flex-lg-row">
            <div class="d-flex flex-center w-lg-25 pt-15 pt-lg-0 px-10"></div>
            <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
                <div class="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
                    <div class="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                        <form class="form w-100" id="kt_sign_in_form" [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPassword()">
                            <div class="text-center mb-11">
                                <a href="javascript:;">
                                    <img alt="Logo" src="assets/new_assets/media/logos/logo.png" class="h-65px" />
                                </a>
                                <h1 class="text-dark fw-bolder mb-3">Nirmanalekh</h1>
                                <h2 class="text-dark fw-bolder mb-3">Tribal Welfare Department</h2>
                                <h2 class="text-dark fw-bolder mb-3">Madhya Pradesh</h2>
                                <div class="text-gray-500 fw-semibold fs-6">Forgot Password ?</div>
                                <div class="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>
                            </div>
                            <div class="fv-row mb-8">
                                <input formControlName="email" type="text" placeholder="Email" autocomplete="off" class="form-control bg-transparent" [ngClass]="{'inputError': f.email.touched && f.email.invalid && f.email.errors && f.email.errors.required }" [ngClass]="{'inputError': formErrorMessages.emailError }" />
                            </div>
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
                                <button type="submit" id="kt_password_reset_submit" class="btn btn-primary me-4">
                                    <span class="indicator-label">Submit</span>
                                    <span class="indicator-progress">Please wait...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                                <a [routerLink]="['/login']" class="btn btn-light">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>