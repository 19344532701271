import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Hospital } from "../models/hospital";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class HospitalService {
	
	private getHospitalUrl = environment.baseURL + "hospital/getHospital";

	private getHospitalByIdUrl = environment.baseURL + "hospital/getHospitalById";

	private addHospitalUrl = environment.baseURL + "hospital/createHospital";

	private updateHospitalUrl = environment.baseURL + "hospital/updateHospital";

	private deleteHospitalUrl = environment.baseURL + "hospital/deleteHospital";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getHospital(params: any): Observable<Hospital> {
		return this.http
		.post<Hospital>(this.getHospitalUrl, params, this.httpOptions)
		.pipe(
			map((HospitalList) => {
			return HospitalList;
			})
		);
	}
	
	getHospitalById(hospitalId: any): Observable<Hospital> {
		let hospitalIds = { hospitalId: hospitalId };
		return this.http
		.post<Hospital>(this.getHospitalByIdUrl, hospitalIds, this.httpOptions)
		.pipe(
			map((HospitalList) => {
			return HospitalList;
			})
		);
	}

	addHospital(hospital: Hospital): Observable<Hospital> {
		return this.http
		.post<Hospital>(this.addHospitalUrl, hospital, this.httpOptions)
		.pipe(
			map((hospital) => {
			return hospital;
			})
		);
	}

	updateHospital(hospital: Hospital): Observable<Hospital> {
		return this.http
		.post<Hospital>(this.updateHospitalUrl, hospital, this.httpOptions)
		.pipe(
			map((hospital) => {
			return hospital;
			})
		);
	}

	deleteHospital(hospital: Hospital): Observable<Hospital> {
		return this.http
		.post<Hospital>(this.deleteHospitalUrl, hospital, this.httpOptions)
		.pipe(
			map((hospital) => {
			return hospital;
			})
		);
	}
}
