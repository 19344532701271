import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { CommunityService } from "../services/community.service";
import { Community } from "../models/community";

@Component({
	selector: "app-community-list",
	templateUrl: "./community-list.component.html",
	styleUrls: ["./community-list.component.css"],
})
export class CommunityListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	communityList: any;
	
	errorMessage = "";
	successMessage = "";
	
	constructor(
		private communityService: CommunityService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getCommunity();
	}

	getCommunity(): void {
		this.communityList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.communityService.getCommunity(dataTablesParameters).subscribe(
				(data) => {
					that.communityList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}
}