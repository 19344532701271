import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BDashboardComponent } from './b-dashboard.component';

import { AuthGuard } from '../auth.guard';
import { AuthBankGuard } from '../authbank.guard';

const routes: Routes = [
  {
    path: 'b_dashboard',
    canActivate: [AuthGuard, AuthBankGuard],
    component: BDashboardComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BDashboardRoutingModule { }
