import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { BloodTypeService } from "../services/bloodType.service";
import { BloodType } from "../models/bloodType";

@Component({
	selector: "app-blood-type-list",
	templateUrl: "./blood-type-list.component.html",
	styleUrls: ["./blood-type-list.component.css"],
})
export class BloodTypeListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addBloodTypeForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
  	});

  	updateBloodTypeForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		bloodTypeId: new FormControl("", [Validators.required]),
  	});

	deleteBloodTypeForm = new FormGroup({
		bloodTypeId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addBloodTypeForm.controls;
	}

	bloodTypeList: any;
	bloodTypeById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		nameError : ""
	};

	constructor(
		private bloodTypeService: BloodTypeService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getBloodType();
	}

	getBloodType(): void {
		this.bloodTypeList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bloodTypeService.getBloodType(dataTablesParameters).subscribe(
				(data) => {
					that.bloodTypeList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openBloodTypeAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBloodTypeEdit(content: any, bloodTypeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bloodTypeService.getBloodTypeById(bloodTypeId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.bloodTypeById = data.data;
			this.updateBloodTypeForm.controls["name"].setValue(data.data.name);
			this.updateBloodTypeForm.controls["bloodTypeId"].setValue(data.data.id);
			} else {
			this.bloodTypeById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBloodTypeView(content: any, bloodTypeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bloodTypeService.getBloodTypeById(bloodTypeId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.bloodTypeById = data.data;
			} else {
			this.bloodTypeById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBloodTypeDelete(content: any, bloodTypeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteBloodTypeForm.controls["bloodTypeId"].setValue(bloodTypeId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addBloodType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.addBloodTypeForm.value.name.trim();
		if (
		!name
		) {
		if (!name) {
			this.formErrorMessages.nameError = "BloodType name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.bloodTypeService
		.addBloodType({
			name
		} as BloodType)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addBloodTypeModalClose")?.click();
				this.addBloodTypeForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateBloodType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.updateBloodTypeForm.value.name.trim();
		let bloodTypeId = this.updateBloodTypeForm.value.bloodTypeId;
		if (
		!name ||
		!bloodTypeId
		) {
		if (!name) {
			this.formErrorMessages.nameError = "BloodType name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.bloodTypeService
		.updateBloodType({
			name,
			bloodTypeId
		} as BloodType)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateBloodTypeModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteBloodType(): void {

		let bloodTypeId = this.deleteBloodTypeForm.value.bloodTypeId;
		if (!bloodTypeId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.bloodTypeService.deleteBloodType({ bloodTypeId } as BloodType).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteBloodTypeModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}