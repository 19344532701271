import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { UserService } from "../services/user.service";

@Component({
	selector: "app-user-view",
	templateUrl: "./user-view.component.html",
	styleUrls: ["./user-view.component.css"],
})
export class UserViewComponent implements OnInit {
  	
	userId: any;
	userById: any;
	
	constructor(
		private userService: UserService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getUserById(this.userId);
	}

	getUserById(userId: any): void {
		this.userService.getUserById(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				console.log(data.data);
				this.userById = data.data;
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}

}