import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Community } from "../models/community";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class CommunityService {
	
	private getCommunityUrl = environment.baseURL + "community/getCommunity";

	private getCommunityByIdUrl = environment.baseURL + "community/getCommunityById";

	private addCommunityUrl = environment.baseURL + "community/createCommunity";

	private updateCommunityUrl = environment.baseURL + "community/updateCommunity";

	private deleteCommunityUrl = environment.baseURL + "community/deleteCommunity";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getCommunity(params: any): Observable<Community> {
		return this.http
		.post<Community>(this.getCommunityUrl, params, this.httpOptions)
		.pipe(
			map((CommunityList) => {
			return CommunityList;
			})
		);
	}
	
	getCommunityById(communityId: any): Observable<Community> {
		let communityIds = { communityId: communityId };
		return this.http
		.post<Community>(this.getCommunityByIdUrl, communityIds, this.httpOptions)
		.pipe(
			map((CommunityList) => {
			return CommunityList;
			})
		);
	}

	addCommunity(community: Community): Observable<Community> {
		return this.http
		.post<Community>(this.addCommunityUrl, community, this.httpOptions)
		.pipe(
			map((community) => {
			return community;
			})
		);
	}

	updateCommunity(community: Community): Observable<Community> {
		return this.http
		.post<Community>(this.updateCommunityUrl, community, this.httpOptions)
		.pipe(
			map((community) => {
			return community;
			})
		);
	}

	deleteCommunity(community: Community): Observable<Community> {
		return this.http
		.post<Community>(this.deleteCommunityUrl, community, this.httpOptions)
		.pipe(
			map((community) => {
			return community;
			})
		);
	}
}
