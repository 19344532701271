import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Disease } from "../models/disease";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class DiseaseService {
	
	private getDiseaseUrl = environment.baseURL + "disease/getDisease";

	private getDiseaseByIdUrl = environment.baseURL + "disease/getDiseaseById";

	private addDiseaseUrl = environment.baseURL + "disease/createDisease";

	private updateDiseaseUrl = environment.baseURL + "disease/updateDisease";

	private deleteDiseaseUrl = environment.baseURL + "disease/deleteDisease";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getDisease(params: any): Observable<Disease> {
		return this.http
		.post<Disease>(this.getDiseaseUrl, params, this.httpOptions)
		.pipe(
			map((diseaseList) => {
			return diseaseList;
			})
		);
	}
	
	getDiseaseById(diseaseId: any): Observable<Disease> {
		let diseaseIds = { diseaseId: diseaseId };
		return this.http
		.post<Disease>(this.getDiseaseByIdUrl, diseaseIds, this.httpOptions)
		.pipe(
			map((diseaseList) => {
			return diseaseList;
			})
		);
	}

	addDisease(disease: Disease): Observable<Disease> {
		return this.http
		.post<Disease>(this.addDiseaseUrl, disease, this.httpOptions)
		.pipe(
			map((disease) => {
			return disease;
			})
		);
	}

	updateDisease(disease: Disease): Observable<Disease> {
		return this.http
		.post<Disease>(this.updateDiseaseUrl, disease, this.httpOptions)
		.pipe(
			map((disease) => {
			return disease;
			})
		);
	}

	deleteDisease(disease: Disease): Observable<Disease> {
		return this.http
		.post<Disease>(this.deleteDiseaseUrl, disease, this.httpOptions)
		.pipe(
			map((disease) => {
			return disease;
			})
		);
	}
}
