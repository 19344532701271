import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { VerifyOtpRoutingModule } from './verify-otp-routing.module';
import { VerifyOtpComponent } from './verify-otp.component';

@NgModule({
  imports: [
    CommonModule,
    VerifyOtpRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [VerifyOtpComponent]
})
export class VerifyOtpModule { }
