import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { BProfileRoutingModule } from './b-profile-routing.module';
import { BProfileComponent } from './b-profile.component';

@NgModule({
  imports: [
    CommonModule,
    BProfileRoutingModule
  ],
  declarations: [BProfileComponent],
  providers: [AuthGuard]
})
export class BProfileModule { }
