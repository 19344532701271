import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../auth.guard';

import { PDashboardRoutingModule } from './p-dashboard-routing.module';
import { PDashboardComponent } from './p-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    PDashboardRoutingModule
  ],
  declarations: [PDashboardComponent],
  providers: [AuthGuard]
})
export class PDashboardModule { }
