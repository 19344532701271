import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HDashboardComponent } from './h-dashboard.component';

import { AuthGuard } from '../auth.guard';
import { AuthHospitalGuard } from '../authhospital.guard';

const routes: Routes = [
  {
    path: 'h_dashboard',
    canActivate: [AuthGuard, AuthHospitalGuard],
    component: HDashboardComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HDashboardRoutingModule { }
