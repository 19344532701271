import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { UserService } from "../services/user.service";
import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: "app-user-blood-request",
	templateUrl: "./user-blood-request.component.html",
	styleUrls: ["./user-blood-request.component.css"],
})
export class UserBloodRequestComponent implements OnInit {
  	
	userId: any;
	userById: any;
	bloodRequestByUserId: any;
	
	constructor(
		private userService: UserService,
		private bloodRequestService: BloodRequestService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getBloodRequestByUserId(this.userId);
		this.getUserById(this.userId);
	}

	getBloodRequestByUserId(userId: any): void {
		this.bloodRequestService.getBloodRequestByUserId(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				this.bloodRequestByUserId = data.data;
			} else {
				this.bloodRequestByUserId = [];
			}
		},
		(error) => {
		}
		);
	}
	
	getUserById(userId: any): void {
		this.userService.getUserById(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				console.log(data.data);
				this.userById = data.data;
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}
}